import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Row, Col, Modal, Button, Form, Input, Select, DatePicker, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { ComplaintsAndCertificatesContext } from '../context';
import { bodyPartsComplaints } from '@/utils/enum';
import Api from '@/services/api';

const { Option } = Select;

export function Complaint() {
	const { complaint, setErrors, closeEditModal, setTriggerReload, complaintUpdateModal } = useContext(
		ComplaintsAndCertificatesContext
	);

	const [form] = Form.useForm();
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const organizationId = useSelector(({ organization }) => organization.organization?.id);

	const hasComplaint = useCallback(
		({ body_part, collection_date, description }) => {
			const dateFormat = moment(collection_date);
			form.setFieldsValue({
				body_part,
				description,
				...(collection_date && { collection_date: dateFormat })
			});
		},
		[form]
	);

	useEffect(() => {
		if (complaint) {
			hasComplaint(complaint);
		}
	}, [complaint, hasComplaint]);

	function onClose() {
		form.resetFields();
		closeEditModal();
	}

	async function onSubmit() {
		const values = await form.validateFields();
		onSave(values);
	}

	const Footer = () => [
		<Button key="cancel" onClick={() => onClose(form)}>
			{I18n.get('Cancel')}
		</Button>,
		<Button key="submit" type="primary" onClick={onSubmit} loading={loadingSubmit}>
			{I18n.get('Save')}
		</Button>
	];

	async function onSave({ description, collection_date, body_part }) {
		try {
			setLoadingSubmit(true);

			const collectionDateFormat = moment(collection_date).startOf('day').format();

			const payload = {
				collection_date: collectionDateFormat,
				organization_id: organizationId,
				description,
				body_part
			};

			const { data } = await Api.put(`/complaint/${complaint.id}?organization_id=${organizationId}`, payload);
			message.success(I18n.get(data.message));
			closeEditModal();
			form.resetFields();
			setTriggerReload();
		} catch (errors) {
			setErrors(errors);
		} finally {
			setLoadingSubmit(false);
		}
	}

	return (
		<Modal
			width={600}
			onOK={onSubmit}
			footer={<Footer />}
			visible={complaintUpdateModal}
			onCancel={() => onClose(form)}
			title={I18n.get('Fill the information to be updated:')}
		>
			<Form form={form} layout="vertical" name="edit_complaint">
				<Row gutter={[10]}>
					<Col sm={24}>
						<Form.Item
							name="body_part"
							label={I18n.get('Body parts')}
							rules={[
								{
									required: true,
									message: I18n.get('Please select a body part')
								}
							]}
						>
							<Select
								showSearch
								mode="tags"
								style={{ width: '100%' }}
								optionFilterProp="children"
								placeholder={I18n.get('Select an option')}
							>
								{Object.keys(bodyPartsComplaints).map((key, index) => (
									<Option key={index} value={key}>
										{I18n.get(bodyPartsComplaints[key])}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col sm={10}>
						<Form.Item
							name="collection_date"
							label={I18n.get('Date of colletion')}
							rules={[
								{
									message: I18n.get('Insert the date of collection'),
									required: true
								}
							]}
						>
							<DatePicker
								format={'L'}
								placeholder={I18n.get('Date of colletion')}
								style={{ width: '100%' }}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="description"
							label={I18n.get('Description')}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid description')
								}
							]}
						>
							<Input.TextArea rows={4} maxLength={150} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
