import React from 'react';
import { Col, DatePicker } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

const Title = styled.div`
	margin-right: 5px;
	display: inline-block;
`;

export const Header = ({ onChangeDate }) => (
	<Col sm={24} style={{ paddingBottom: '15px' }}>
		<Title>
			<h2>{I18n.get('Health Matrix')}</h2>
		</Title>
		<DatePicker.RangePicker
			picker="month"
			onChange={onChangeDate}
			style={{ float: 'right' }}
		/>
	</Col>
);
