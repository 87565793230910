import React, { useState } from 'react';
import { Row, Col, Form } from 'antd';
import { debounce } from 'lodash';
import { I18n } from '@aws-amplify/core';

import { useMtmCodes } from '@/hooks';
import { ModalBorder } from '@/components/ui';
import { Title } from '@/components/Typography';
import { OptionType } from '@/components/views/MtmReport/context/types';
import { useMtmReportContext } from '@/components/views/MtmReport/context';
import { DisabledTextInput, MtmActivity } from '../../../components/Inputs';
import { CustomButton } from '../../../components/styles';

const { useWatch, useFormInstance } = Form;

interface ModalCodeProps {
	open?: boolean;
	onClose: () => void;
	disableFrequency: (code?: string) => void;
	setAggregationValue: (type?: string) => void;
	operation_id?: string;
}

export function ModalCode({ operation_id = '', onClose, open = false, disableFrequency, setAggregationValue }: ModalCodeProps) {
	const [meaning, setMeaning] = useState<string>('');
	const form = useFormInstance();
	const { basicParams } = useMtmReportContext();

	const namePlace = ['modal_mtm', operation_id];
	const mtmCode = useWatch([...namePlace, 'mtm_code_id'], form);

	const { data: mtmCodes, isFetching: gettingMtmCodes } = useMtmCodes({
		...basicParams,
		meaning: meaning.toUpperCase().trim(),
		enabled: open
	});

	const handleDebounced = debounce((value) => {
		setMeaning(value);
	}, 1000);

	function resetFields() {
		form.setFieldValue([...namePlace, 'tmu'], undefined);
		form.setFieldValue([...namePlace, 'code'], undefined);
		form.setFieldValue([...namePlace, 'hours'], undefined);
		form.setFieldValue([...namePlace, 'minutes'], undefined);
		form.setFieldValue([...namePlace, 'seconds'], undefined);
		form.setFieldValue([...namePlace, 'meaning'], undefined);
		form.setFieldValue([...namePlace, 'mtm_code_id'], undefined);
	}

	function handleOnCancel() {
		onClose();
		resetFields();
	}

	function handleOnAdd() {
		const namePlaceCard = ['operation_mtm', operation_id];
		const code = mtmCodes?.find((f) => f.id === mtmCode);
		if (!code) {
			return;
		}
		const code_type = code?.aggregation_value.substring(0,3);
		disableFrequency(code.code);
		setAggregationValue(code.aggregation_value);
		form.setFieldValue([...namePlaceCard, 'tmu'], code.tmu);
		form.setFieldValue([...namePlaceCard, 'seconds'], code.seconds);
		form.setFieldValue([...namePlaceCard, 'mtm_code_id'], {
			value: code.id,
			label: code.code
		});
		handleOnCancel();
	}

	const handleSelect = (value: OptionType) => {
		if (!value) {
			return resetFields();
		}

		const mtm_code = mtmCodes?.find(({ id }) => id === value.value);
		form.setFieldValue([...namePlace, 'tmu'], mtm_code?.tmu);
		form.setFieldValue([...namePlace, 'code'], mtm_code?.code);
		form.setFieldValue([...namePlace, 'hours'], mtm_code?.hours);
		form.setFieldValue([...namePlace, 'seconds'], mtm_code?.seconds);
		form.setFieldValue([...namePlace, 'minutes'], mtm_code?.minutes);
		form.setFieldValue([...namePlace, 'meaning'], mtm_code?.meaning);
	};

	const placeholder = (0).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

	return (
		<ModalBorder
			open={open}
			width={'90vw'}
			footer={false}
			$borderSize="15px"
			onCancel={handleOnCancel}
			style={{ maxWidth: '620px' }}
		>
			<Row gutter={[30, 0]}>
				<Col span={24}>
					<Title level={2}>TMU code</Title>
				</Col>

				<Col span={24}>
					<MtmActivity
						loading={gettingMtmCodes}
						codes={mtmCodes}
						namePlace={namePlace}
						onSearch={handleDebounced}
						onChange={handleSelect}
					/>
				</Col>

				<Col span={24}>
					<DisabledTextInput namePlace={namePlace} name="code" title="Code" placeholder="######" />
				</Col>
				<Col span={12}>
					<DisabledTextInput namePlace={namePlace} name="tmu" title="TMU" placeholder="000" />
				</Col>
				<Col span={12}>
					<DisabledTextInput namePlace={namePlace} name="seconds" title="Seconds" placeholder={placeholder} />
				</Col>
				<Col span={12}>
					<DisabledTextInput namePlace={namePlace} name="minutes" title="Minutes" placeholder={placeholder} />
				</Col>
				<Col span={12}>
					<DisabledTextInput namePlace={namePlace} name="hours" title="Hours" placeholder={placeholder} />
				</Col>
				<Col span={24}>
					<DisabledTextInput
						namePlace={namePlace}
						overflow
						name="meaning"
						title="Meaning"
						placeholder={'Meaning'}
					/>
				</Col>
				{/* <Col span={24}>
					<DisabledTextInput namePlace={namePlace} name="aggregation_value" title="Value-added" />
				</Col> */}
			</Row>
			<Row justify="center" align="middle" gutter={[20, 0]}>
				<Col>
					<CustomButton htmlType="button" size="large" type="ghost" onClick={handleOnCancel}>
						{I18n.get('Cancel')}
					</CustomButton>
				</Col>
				<Col>
					<CustomButton
						htmlType="button"
						size="large"
						type="primary"
						disabled={!mtmCode}
						onClick={handleOnAdd}
					>
						{I18n.get('Add')}
					</CustomButton>
				</Col>
			</Row>
		</ModalBorder>
	);
}
