import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import * as S from '../styles';
import { getDecimalSeparator } from '@/utils/getDecimalSeparator';

interface SecondsProps {
	fontSize?: string;
	namePlace: string[];
	disabled?: boolean;
}

export function Seconds({ fontSize, namePlace, disabled = true }: Readonly<SecondsProps>) {
	return (
		<S.ContainerInputNumber width={'100%'} $borderRadius="8px">
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[...namePlace, 'seconds']}
				label={<RequiredLabel bold fontSize={fontSize} title={'Seconds'} isRequired={false} />}
			>
				<InputNumber
					min={0}
					keyboard
					step={0.01}
					size="large"
					placeholder={'0'}
					disabled={disabled}
					decimalSeparator={getDecimalSeparator()}
				/>
			</Form.Item>
		</S.ContainerInputNumber>
	);
}
