import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';

import { Card } from '../Card';
import {
	getComplaints,
	getCertificates,
	getRestricts,
	getWorkers,
	getWomen,
	getPwds,
} from '../services';
import { AnalyticsDashboardContext } from '../context';

const initState = [false, false, false, false, false];

export const Painel = () => {
	const { setFilter, filter } = useContext(AnalyticsDashboardContext);
	const [clicked, setClicked] = useState(initState);

	let pcd, gender, restrict, complaint, medical_certificate;

	useEffect(() => {
		if (Object.keys(filter).length === 2) {
			setClicked(initState);
			pcd = null;
			gender = null;
			restrict = null;
			complaint = null;
			medical_certificate = null;
		}
	}, [filter]);

	function onClickCard(title) {
		if (title === 'complaints') {
			if (clicked[0]) {
				complaint = null;
				setClicked((prev) => ({ ...prev, [0]: false }));
				!complaint && setFilter({ complaint });
			}

			if (!clicked[0]) {
				complaint = true;
				setClicked((prev) => ({ ...prev, [0]: true }));
				complaint && setFilter({ complaint });
			}
		}

		if (title === 'certificates') {
			if (clicked[1]) {
				medical_certificate = null;
				setClicked((prev) => ({ ...prev, [1]: false }));
				!medical_certificate && setFilter({ medical_certificate });
			}

			if (!clicked[1]) {
				medical_certificate = true;
				setClicked((prev) => ({ ...prev, [1]: true }));
				medical_certificate && setFilter({ medical_certificate });
			}
		}

		if (title === 'women') {
			if (clicked[2]) {
				gender = null;
				setClicked((prev) => ({ ...prev, [2]: false }));
				!gender && setFilter({ gender });
			}

			if (!clicked[2]) {
				gender = 'FEMALE';
				setClicked((prev) => ({ ...prev, [2]: true }));
				gender && setFilter({ gender });
			}
		}

		if (title === 'pwds') {
			if (clicked[3]) {
				pcd = null;
				setClicked((prev) => ({ ...prev, [3]: false }));
				!pcd && setFilter({ pcd });
			}

			if (!clicked[3]) {
				pcd = true;
				setClicked((prev) => ({ ...prev, [3]: true }));
				pcd && setFilter({ pcd });
			}
		}

		if (title === 'restricts') {
			if (clicked[4]) {
				restrict = null;
				setClicked((prev) => ({ ...prev, [4]: false }));
				!restrict && setFilter({ restrict });
			}

			if (!clicked[4]) {
				restrict = 'BOTH';
				setClicked((prev) => ({ ...prev, [4]: true }));
				restrict && setFilter({ restrict });
			}
		}
	}

	return (
		<Col xs={24} sm={24} md={24}>
			<Row
				align="middle"
				justify="center"
				gutter={[10, 0]}
				style={{ paddingTop: '10px', paddingBottom: '10px' }}
			>
				<Col md={12}>
					<Row gutter={[10, 10]} justify="end">
						<Col sm={4}>
							<Card
								onClick={() => onClickCard('complaints')}
								icon={<Icons.FrownOutlined />}
								title={I18n.get('Complaints')}
								onFetch={getComplaints}
								attribute="complaint"
								active={clicked[0]}
								filter={filter}
							/>
						</Col>
						<Col sm={4}>
							<Card
								onClick={() => onClickCard('certificates')}
								icon={<Icons.SnippetsOutlined />}
								title={I18n.get('Med. certificates')}
								onFetch={getCertificates}
								attribute="certificate"
								active={clicked[1]}
								filter={filter}
							/>
						</Col>
						<Col sm={4}>
							<Card
								icon={<Icons.IdcardOutlined />}
								title={I18n.get('Workers')}
								onFetch={getWorkers}
								attribute="worker"
								filter={filter}
							/>
						</Col>
					</Row>
				</Col>
				<Col md={12}>
					<Row gutter={[10, 10]}>
						<Col sm={4}>
							<Card
								onClick={() => onClickCard('women')}
								icon={<Icons.WomanOutlined />}
								title={I18n.get('Women')}
								onFetch={getWomen}
								attribute="women"
								active={clicked[2]}
								filter={filter}
							/>
						</Col>
						<Col sm={4}>
							<Card
								onClick={() => onClickCard('pwds')}
								icon={<Icons.WarningOutlined />}
								title={I18n.get('PWD')}
								onFetch={getPwds}
								attribute="pcd"
								active={clicked[3]}
								filter={filter}
							/>
						</Col>
						<Col sm={4}>
							<Card
								onClick={() => onClickCard('restricts')}
								icon={<Icons.StopOutlined />}
								title={I18n.get('Restricts')}
								onFetch={getRestricts}
								attribute="restrict"
								active={clicked[4]}
								filter={filter}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
	);
};
