import React from 'react';

const defaultState = {
	companies: null,
	selectCompanyId: null,
	sectors: null,
	selectSectorId: null,
	lines: null,
	selectLineId: null,
	workstations: null,
	selectWorkstationId: null,
	workers: null,
	isFetchWorkers: true,
	workerCreateModal: false,
	isLoadingWorkerCreate: false,
	workerUpdateModal: false,
	triggerReload: null,
	worker: null,
	errors: null,
	complaintOrCertificateModal: false,
	complaintModal: false,
	medicalCertificateModal: false,
	workstationsByWorker: null,
	cidm: null,
};

const defaultContext = {
	...defaultState,
	setSelectCompanyId: () => {},
	setSelectSectorId: () => {},
	setSelectLineId: () => {},
	onFilterWorker: () => {},
	setTriggerReload: () => {},
	onCreateWorker: () => {},
	onDeleteWorker: () => {},
	showWorkerCreateModal: () => {},
	closeWorkerCreateModal: () => {},
	showWorkerEditModal: () => {},
	closeWorkerEditModal: () => {},
	setErrors: () => {},
	getCompanies: () => {},
	getSectors: () => {},
	getLines: () => {},
	getWorkstations: () => {},
	showComplaintOrCertificateModal: () => {},
	closeComplaintOrCertificateModal: () => {},
	showComplaintModal: () => {},
	closeComplaintModal: () => {},
	showMedicalCertificateModal: () => {},
	closeMedicalCertificateModal: () => {},
	setWorkstationsByWorker: () => {},
	getCidM: () => {},
};

export const ManagementContext = React.createContext(defaultContext);
