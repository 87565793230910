import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Form, Button, Input, InputNumber, DatePicker, TimePicker } from 'antd';

import { Title } from './Title';
import { AETContext } from '../context';

const Container = Row;
const FormContent = Col;
const { useForm } = Form;

export function Apresentation() {
	const { setData, nextStep, ...restData } = useContext(AETContext);

	const [form] = useForm();

	function onFinish(values) {
		setData(values);
		nextStep();
	}

	const maskDocument = (value) => {
		value = value.replace(/\D/g, '');

		if (value.length <= 11) {
			value = value.replace(/(\d{3})(\d)/, '$1.$2');
			value = value.replace(/(\d{3})(\d)/, '$1.$2');
			value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
		} else {
			value = value.replace(/^(\d{2})(\d)/, '$1.$2');
			value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
			value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
			value = value.replace(/(\d{4})(\d)/, '$1-$2');
		}

		return value;
	};

	const formatDocument = (e) => {
		const document = maskDocument(e.target.value);
		form.setFieldsValue({ cnpj: document });
	};

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			style={{ minHeight: '500px' }}
			initialValues={{ ...restData }}
		>
			<Container>
				<Title>Plant Information</Title>
				<FormContent sm={20}>
					<Row gutter={[10, 0]}>
						<Col sm={12}>
							<Form.Item
								name="company"
								label={I18n.get('Company')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter company name'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={12}>
							<Form.Item
								name="fantasy_name"
								label={I18n.get('Company name')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Insert a company name'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8}>
							<Form.Item
								name="cnpj"
								label={I18n.get('Tax Code')}
								validateTrigger={['onChange', 'onBlur']}
								rules={[
									() => ({
										validator(_, value) {
											if (!value) {
												return Promise.reject(I18n.get('Enter a Tax Number'));
											}

											if (value.length < 14 || value.length > 18) {
												return Promise.reject(I18n.get('Insert a valid Tax Number'));
											}

											return Promise.resolve();
										}
									})
								]}
							>
								<Input onChange={formatDocument} maxLength={18} autoComplete="off" />
							</Form.Item>
							{/* <Form.Item
								name="cnpj"
								label="CNPJ"
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(
														I18n.get(
															'Enter the CNPJ'
														)
												  )
												: Promise.resolve();
										},
									}),
								]}
							>
								<Input />
							</Form.Item> */}
						</Col>
						<Col sm={8}>
							<Form.Item
								name="cnae"
								label="CNAE"
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter the Company Activities'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8}>
							<Form.Item
								name="risk_degree"
								label={I18n.get('Risk degree')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter the degree of risk'))
												: Promise.resolve();
										}
									})
								]}
							>
								<InputNumber min={1} max={4} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col sm={24}>
							<Form.Item
								name="company_activity"
								label={I18n.get('Activity')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Insira a atividade'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={24}>
							<h3>{I18n.get('Localization')}</h3>
						</Col>
						<Col sm={10}>
							<Form.Item
								name="address"
								label={I18n.get('Address')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter the address'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={6}>
							<Form.Item
								name="zipcode"
								label={I18n.get('Zipcode')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter the zip code'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8}>
							<Form.Item
								name="district"
								label={I18n.get('District')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter the district'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8}>
							<Form.Item
								name="city"
								label={I18n.get('City')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter the city'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8}>
							<Form.Item
								name="state"
								label={I18n.get('State')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter the state'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={8}>
							<Form.Item
								name="country"
								label={I18n.get('Country')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Enter the state'))
												: Promise.resolve();
										}
									})
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col sm={24}>
							<h3>{I18n.get('Normal working hours')}</h3>
						</Col>
						<Col sm={24}>
							<Row gutter={[10, 0]}>
								<Col sm={12}>
									<Form.List name="administrative_hours">
										{(fields, { add, remove }, { errors }) => {
											const firstItem = fields.length === 0;
											return (
												<div>
													{fields.map(({ key, name, fieldKey, ...restField }, index) => (
														<Row
															key={key}
															gutter={[1, 0]}
															type="flex"
															{...(index > 0 && {
																style: {
																	alignItems: 'baseline'
																}
															})}
															justify="space-around"
															align="middle"
														>
															<Col sm={22}>
																<Form.Item
																	{...restField}
																	name={[name, 'time']}
																	fieldKey={[fieldKey, 'time']}
																	label={
																		index === 0 ? I18n.get('Administrative') : ''
																	}
																	rules={[
																		() => ({
																			validator(_, value) {
																				return !value
																					? Promise.reject(
																							I18n.get(
																								'Enter working hours'
																							)
																					  )
																					: Promise.resolve();
																			}
																		})
																	]}
																>
																	<TimePicker.RangePicker
																		format="HH:mm"
																		placeholder={[
																			I18n.get('Initial'),
																			I18n.get('Final')
																		]}
																		style={{
																			width: '100%'
																		}}
																	/>
																</Form.Item>
															</Col>
															<Col>
																<DeleteOutlined onClick={() => remove(name)} />
															</Col>
														</Row>
													))}
													{fields.length < 4 && (
														<Form.Item>
															<div
																style={{
																	textAlign: 'center',
																	...(firstItem && {
																		paddingTop: '20px'
																	})
																}}
															>
																{firstItem && <p>{I18n.get('Administrativo')}</p>}
																<Button
																	shape="circle"
																	icon={<PlusOutlined />}
																	onClick={() => add()}
																/>
															</div>
															<Form.ErrorList errors={errors} />
														</Form.Item>
													)}
												</div>
											);
										}}
									</Form.List>
								</Col>
								<Col sm={12}>
									<Form.List name="operational_hours">
										{(fields, { add, remove }, { errors }) => {
											const firstItem = fields.length === 0;
											return (
												<div>
													{fields.map(({ key, name, fieldKey, ...restField }, index) => (
														<Row
															key={key}
															gutter={[1, 0]}
															type="flex"
															{...(index > 0 && {
																style: {
																	alignItems: 'baseline'
																}
															})}
															justify="space-around"
															align="middle"
														>
															<Col>
																<Form.Item
																	{...restField}
																	name={[name, 'round']}
																	fieldKey={[fieldKey, 'round']}
																	label={index === 0 ? I18n.get('Work Shift') : ''}
																	rules={[
																		() => ({
																			validator(_, value) {
																				return !value
																					? Promise.reject(
																							I18n.get('Enter the shift')
																					  )
																					: Promise.resolve();
																			}
																		})
																	]}
																>
																	<InputNumber min={1} max={3} />
																</Form.Item>
															</Col>
															<Col>
																<Form.Item
																	{...restField}
																	name={[name, 'time']}
																	fieldKey={[fieldKey, 'time']}
																	label={index === 0 ? I18n.get('Operational') : ''}
																	rules={[
																		() => ({
																			validator(_, value) {
																				return !value
																					? Promise.reject(
																							I18n.get(
																								'Enter working hours'
																							)
																					  )
																					: Promise.resolve();
																			}
																		})
																	]}
																>
																	<TimePicker.RangePicker
																		format="HH:mm"
																		placeholder={[
																			I18n.get('Initial'),
																			I18n.get('Final')
																		]}
																		style={{
																			width: '100%'
																		}}
																	/>
																</Form.Item>
															</Col>
															<Col>
																<DeleteOutlined onClick={() => remove(name)} />
															</Col>
														</Row>
													))}
													{fields.length < 4 && (
														<Form.Item>
															<div
																style={{
																	textAlign: 'center',
																	...(firstItem && {
																		paddingTop: '20px'
																	})
																}}
															>
																{firstItem && <p>{I18n.get('Operational')}</p>}
																<Button
																	shape="circle"
																	icon={<PlusOutlined />}
																	onClick={() => add()}
																/>
															</div>
															<Form.ErrorList errors={errors} />
														</Form.Item>
													)}
												</div>
											);
										}}
									</Form.List>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row gutter={[10, 10]} style={{ paddingTop: '15px' }}>
						<Col sm={24}>
							<h3>{I18n.get('Staff')}</h3>
						</Col>
						<Col sm={6}>
							<Form.Item name="direct_hourly" label={I18n.get('Direct hourly worker')}>
								<InputNumber min={0} max={99999} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col sm={6}>
							<Form.Item name="indirect_hourly" label={I18n.get('Indirect hourly worker')}>
								<InputNumber min={0} max={99999} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col sm={6}>
							<Form.Item name="monthly_payer" label={I18n.get('Monthly worker')}>
								<InputNumber min={0} max={99999} style={{ width: '100%' }} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[10, 10]} style={{ paddingTop: '15px' }}>
						<Col sm={24}>
							<h3>{I18n.get('Date')}</h3>
						</Col>
						<Col sm={9}>
							<Form.Item
								name="reference_date"
								label={I18n.get('Date of reference')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Select the reference date'))
												: Promise.resolve();
										}
									})
								]}
							>
								<DatePicker format="MM-YYYY" style={{ width: '100%' }} picker="month" />
							</Form.Item>
						</Col>
						<Col sm={9}>
							<Form.Item
								name="date_report"
								label={I18n.get('Date of the report')}
								rules={[
									() => ({
										validator(_, value) {
											return !value
												? Promise.reject(I18n.get('Select the report date'))
												: Promise.resolve();
										}
									})
								]}
							>
								<DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
							</Form.Item>
						</Col>
					</Row>
				</FormContent>
			</Container>
			<Col sm={24}>
				<Button type="primary" htmlType="submit">
					{I18n.get('Next')}
				</Button>
			</Col>
		</Form>
	);
}
