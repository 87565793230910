import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Report } from './Report';
import { MtmReportProvider } from './context';
import { Text } from '@/components/Typography';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useGetMtmReport } from '@/hooks/useGetMtmReport';
import { useApplicationContext } from '@/context/application';
import type { BaseContextOptional } from '@/types';
import type { MtmReportParams } from './types';

export default function MtmReport() {
	const { search } = useLocation();
	const { id } = useParams<MtmReportParams>();
	const { organization, company } = useApplicationContext();

	const searchParams = new URLSearchParams(search);
	const queryParams: BaseContextOptional = Object.fromEntries(searchParams.entries());

	const basicParams: BaseContextOptional = {
		organization_id: queryParams.organization_id ?? organization?.id,
		company_id: queryParams.company_id ?? company?.id
	};

	const { isLoading, data: mtmReport, isError: mtmReportError } = useGetMtmReport({ ...basicParams, id });

	const isError = mtmReportError;

	if (isLoading && id) {
		return <LoadingSkeleton />;
	}

	if (isError) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	return (
		<MtmReportProvider basicParams={basicParams} mtmReport={mtmReport}>
			<Report />
		</MtmReportProvider>
	);
}
