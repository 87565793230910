import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getElementAtEvent } from 'react-chartjs-2';

import Api from '@/services/api';

import { Header } from './Header';
import { LineList } from './LineList';
import { MatrixChart } from './MatrixChart';
import { LineDetails } from './LineDetails';

import { HealthMatrixContext } from './context';

export function HealthMatrix() {
	const [selectedLine, setSelectedLine] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	async function getLines({ organizationId, startDate, endDate }) {
		if (!organizationId || !startDate || !endDate) return;
		const params = new URLSearchParams();
		params.append('organization_id', organizationId);
		params.append('group_by', 'line');
		params.append('start_date', startDate);
		params.append('end_date', endDate);

		let url = `/appointment/work-metrics?`;
		const { data } = await Api.get(url + params);
		return data;
	}

	async function getLineHierarchy({ organizationId, lineId }) {
		if (!organizationId || !lineId) return;
		let url = `/line/hierarchy/${organizationId}/${lineId}`;
		const { data } = await Api.get(url);
		return data;
	}

	const linesParams = {
		organizationId,
		startDate,
		endDate,
	};

	const lineHierarchyParams = {
		organizationId,
		lineId: selectedLine?.id,
	};

	const lines = useQuery(['lines', 'healthMatrix', linesParams], () =>
		getLines(linesParams)
	);

	const lineHierarchy = useQuery(
		['lines', 'lineHierarchy', lineHierarchyParams],
		() => getLineHierarchy(lineHierarchyParams),
		{ enabled: !!selectedLine }
	);

	const selectedLineSector = {
		id: lineHierarchy.data?.sector?.id,
		name: lineHierarchy.data?.sector?.name,
	};

	const selectedLineCompany = {
		id: lineHierarchy.data?.sector?.company?.id,
		name: lineHierarchy.data?.sector?.company?.name,
	};

	const onChangeDate = (e) => {
		setStartDate(e ? e[0]._d : null);
		setEndDate(e ? e[1]._d : null);
	};

	function selectBarOnClick(e, ref, data) {
		const [meta] = getElementAtEvent(ref, e);
		if (!meta) return;

		const [selected] = data.filter((x, i) => i === meta.index);
		setSelectedLine(selected);
	}

	function updateSelectedLine(updatedLines) {
		if (!selectedLine || !updatedLines) return;

		const updatedSelectedLine = updatedLines.find(
			(line) => line.id === selectedLine.id
		);
		setSelectedLine(updatedSelectedLine);
	}

	function onClearFilter() {
		setSelectedLine('');
	}

	useEffect(() => {
		let start_date, end_date;
		if (startDate == null) {
			start_date = new Date();
			start_date.setMonth(start_date.getMonth() - 6);
			setStartDate(start_date);
		}
		if (endDate == null) {
			end_date = new Date();
			end_date.setMonth(end_date.getMonth());
			setEndDate(end_date);
		}
	}, [startDate, endDate]);

	useEffect(() => {
		updateSelectedLine(lines.data);
	}, [lines.data]);

	const context = {
		lines,
		selectedLine,
		selectedLineSector,
		selectedLineCompany,
		lineHierarchy,
		startDate,
		endDate,
		selectBarOnClick,
		onClearFilter,
	};

	return (
		<HealthMatrixContext.Provider value={context}>
			<Header onChangeDate={onChangeDate} />
			{!lines.isLoading && lines.data && <MatrixChart />}
			{selectedLine ? <LineDetails /> : <LineList />}
		</HealthMatrixContext.Provider>
	);
}
