import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Input, Button } from 'antd';

import { Title } from './Title';
import { AETContext } from '../context';

const { useForm } = Form;
const Container = Row;
const FormContent = Col;
const TextArea = Input.TextArea;

export function ProductionVolume() {
	const { volume_production, setData, nextStep, previousStep } =
		useContext(AETContext);
	const [form] = useForm();

	function onFinish(values) {
		setData(values);
		nextStep();
	}

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			style={{ minHeight: '500px' }}
			initialValues={{ volume_production }}
		>
			<Container>
				<Title>Production Volume</Title>
				<FormContent sm={20}>
					<Row gutter={[0, 10]}>
						<Col sm={24}>
							<p>{I18n.get('Enter production volumes below')}</p>
						</Col>
						<Col sm={24}>
							<Form.Item name="volume_production">
								<TextArea
									rows={10}
									maxLength={500}
									allowClear
									showCount
								/>
							</Form.Item>
						</Col>
					</Row>
				</FormContent>
				<Col sm={24} style={{ position: 'absolute', bottom: 0 }}>
					<Row gutter={[5]}>
						<Col>
							<Button onClick={previousStep}>
								{I18n.get('Previous')}
							</Button>
						</Col>
						<Col>
							<Form.Item shouldUpdate noStyle>
								{() => (
									<Button type="primary" htmlType="submit">
										{I18n.get('Next')}
									</Button>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Container>
		</Form>
	);
}
