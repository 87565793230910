import React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row as Container, Modal, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { List } from './List';
import { Filter } from './Filter';
import { Create } from './Create';
import { Update } from './Update';
import { Header } from './Header';
import { ComplaintOrCertificate } from './ComplaintOrCertificate';
import { Complaint } from './ComplaintOrCertificate/Complaint';
import { MedicalCertificate } from './ComplaintOrCertificate/MedicalCertificate';

import { ManagementContext } from './context';

import Api from '@/services/api';
import Spinner from '@/components/SpinnerDeprecated';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useParams } from 'react-router-dom';

const initState = {
	companies: [],
	selectCompanyId: '',
	sectors: [],
	selectSectorId: '',
	lines: [],
	selectLineId: '',
	workstations: [],
	selectWorkstationId: '',
	workers: [],
	isFetchWorkers: true,
	workerCreateModal: false,
	isLoadingWorkerCreate: false,
	workerUpdateModal: false,
	triggerReload: false,
	errors: null,
	complaintOrCertificateModal: false,
	complaintModal: false,
	medicalCertificateModal: false,
	workstationsByWorker: null,
	cidm: [],
};

export function Management() {
	const isMounted = useIsMounted();
	const [state, setState] = useState(initState);
	const { type, companyId, sectorId, lineId } = useParams();

	const redirected = type ? true : false;

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	function setSelectCompanyId(value) {
		setState((prev) => ({
			...prev,
			selectCompanyId: value,
			selectSectorId: null,
			selectLineId: null,
		}));
	}

	function setSelectSectorId(value) {
		setState((prev) => ({
			...prev,
			selectSectorId: value,
			selectLineId: null,
		}));
	}

	function setSelectLineId(value) {
		setState((prev) => ({ ...prev, selectLineId: value }));
	}

	function setSelectWorkstationId(value) {
		setState((prev) => ({ ...prev, selectWorkstationId: value }));
	}

	async function onFilterWorker(values) {
		if (!organizationId) return;

		const {
			company,
			sector,
			line,
			workstation,
			register_id,
			name,
			pcd,
			gender,
			restrict,
		} = values || {};

		let url = `/worker?`;

		const params = {
			organization_id: organizationId,
			...(company && { company_id: company }),
			...(sector && { sector_id: sector }),
			...(line && { line_id: line }),
			...(workstation && { workstation_id: workstation }),
			...(register_id && { register_id }),
			...(name && { name }),
			...(pcd && { pcd }),
			...(gender && { gender }),
			...(restrict && { restrict }),
		};

		const paramsMounting = new URLSearchParams(params);

		url += paramsMounting.toString();

		try {
			const { data: workers } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workers }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		} finally {
			setState((prev) => ({ ...prev, isFetchWorkers: false }));
		}
	}

	function showWorkerCreateModal() {
		setState((prev) => ({ ...prev, workerCreateModal: true }));
	}

	function closeWorkerCreateModal() {
		setState((prev) => ({ ...prev, workerCreateModal: false }));
	}

	function showWorkerEditModal(worker) {
		setState((prev) => ({ ...prev, workerUpdateModal: true, worker }));
	}

	function closeWorkerEditModal() {
		setState((prev) => ({ ...prev, workerUpdateModal: false }));
	}

	function showComplaintOrCertificateModal(worker) {
		setState((prev) => ({
			...prev,
			complaintOrCertificateModal: true,
			worker,
		}));
	}

	function closeComplaintOrCertificateModal() {
		setState((prev) => ({ ...prev, complaintOrCertificateModal: false }));
	}

	function showComplaintModal(worker, workstationsByWorker) {
		setState((prev) => ({ ...prev, complaintOrCertificateModal: false }));
		setState((prev) => ({
			...prev,
			complaintModal: true,
			worker,
			workstationsByWorker,
		}));
	}

	function closeComplaintModal() {
		setState((prev) => ({ ...prev, complaintModal: false }));
		setSelectWorkstationId('');
	}

	function showMedicalCertificateModal(worker, workstationsByWorker) {
		setState((prev) => ({ ...prev, complaintOrCertificateModal: false }));
		setState((prev) => ({
			...prev,
			medicalCertificateModal: true,
			worker,
			workstationsByWorker,
		}));
	}

	function closeMedicalCertificateModal() {
		setState((prev) => ({ ...prev, medicalCertificateModal: false }));
	}

	function setWorkstationsByWorker(workstationsByWorker) {
		setState((prev) => ({ ...prev, workstationsByWorker }));
	}

	function setTriggerReload() {
		setState((prev) => ({ ...prev, triggerReload: !prev.triggerReload }));
	}

	function setResetState() {
		setState((prev) => ({
			...prev,
			selectWorkstationId: '',
			selectCompanyId: '',
			selectSectorId: '',
			selectLineId: '',
		}));
	}

	function setErrors(errors) {
		setState((prev) => ({ ...prev, errors }));
	}

	async function onCreateWorker(values) {
		const {
			workstations,
			register_id,
			restrict,
			company,
			gender,
			sector,
			line,
			name,
			pcd,
			form,
		} = values;

		setState((prev) => ({ ...prev, isLoadingWorkerCreate: true }));

		try {
			const body = {
				organization_id: organizationId,
				workstations: workstations,
				company_id: company,
				sector_id: sector,
				line_id: line,
				worker: {
					register_id,
					restrict,
					gender,
					name,
					pcd: JSON.parse(pcd),
				},
			};

			const { data } = await Api.post('/worker', body);
			message.success(I18n.get(data.message));
			closeWorkerCreateModal();
			setTriggerReload();
			setResetState();
			form.resetFields();
		} catch (errors) {
			setState((prev) => ({ ...prev, errors }));
		} finally {
			setState((prev) => ({ ...prev, isLoadingWorkerCreate: false }));
		}
	}

	function onDeleteWorker({ id: workerId }) {
		Modal.confirm({
			title: I18n.get('Warning'),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('No'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Do you want to delete this worker?'),
			onOk() {
				return new Promise(async (resolve, reject) => {
					const url = `/worker/${workerId}?organization_id=${organizationId}`;
					try {
						const { data } = await Api.delete(url);
						resolve();
						message.success(I18n.get(data.message));
						setTriggerReload();
					} catch (errors) {
						setState((prev) => ({ ...prev, errors }));
						reject();
					}
				});
			},
		});
	}

	async function getCompanies(organizationId) {
		if (!organizationId) return;
		let url = `/company/user_list/${organizationId}`;
		try {
			const { data: companies } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, companies }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getSectors(organizationId, companyId) {
		if (!organizationId || !companyId) return;
		let url = `/sector/${organizationId}/${companyId}`;
		try {
			const { data: sectors } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, sectors }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getLines(organizationId, companyId, sectorId) {
		if (!organizationId || !companyId || !sectorId) return;
		let url = `/line/${organizationId}/${companyId}/${sectorId}`;
		try {
			const { data: lines } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, lines }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getWorkstations(organizationId, companyId, sectorId, lineId) {
		if (!organizationId || !companyId || !sectorId || !lineId) return;
		let url = `/workstation/${organizationId}/${companyId}/${lineId}`;
		try {
			const { data: workstations } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workstations }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getWorkers(organizationId) {
		if (!organizationId) return;
		let url = `/worker/?organization_id=${organizationId}`;
		try {
			const { data: workers } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workers }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		} finally {
			isMounted() && setState((prev) => ({ ...prev, isFetchWorkers: false }));
		}
	}

	async function getCidM(organizationId) {
		if (!organizationId) return;
		let url = `/medical_certificate/cidm/?organization_id=${organizationId}`;
		try {
			const { data: cidm } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, cidm }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	const {
		selectCompanyId,
		selectSectorId,
		selectLineId,
		isFetchWorkers,
		triggerReload,
		errors,
	} = state;

	useEffect(() => {
		getCompanies(organizationId);
		if (redirected) return;
		getWorkers(organizationId);
		getCidM(organizationId);
	}, [triggerReload, organizationId]);

	useEffect(() => {
		getSectors(organizationId, selectCompanyId);
	}, [selectCompanyId]);

	useEffect(() => {
		getLines(organizationId, selectCompanyId, selectSectorId);
	}, [selectCompanyId, selectSectorId]);

	useEffect(() => {
		getWorkstations(organizationId, selectCompanyId, selectSectorId, selectLineId);
	}, [selectCompanyId, selectSectorId, selectLineId]);

	useEffect(() => {
		if (!redirected) return;

		const urlParams = {
			company: companyId,
			sector: sectorId,
			line: lineId,
			...(type === 'pcd' && { pcd: true }),
			...(type === 'women' && { gender: 'FEMALE' }),
			...(type === 'restrict' && { restrict: 'BOTH' }),
		};
		onFilterWorker(urlParams);
	}, [redirected]);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	const context = {
		...state,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setSelectWorkstationId,
		onFilterWorker,
		setTriggerReload,
		onCreateWorker,
		onDeleteWorker,
		showWorkerCreateModal,
		closeWorkerCreateModal,
		showWorkerEditModal,
		closeWorkerEditModal,
		setErrors,
		getCompanies,
		getSectors,
		getLines,
		getWorkstations,
		showComplaintOrCertificateModal,
		closeComplaintOrCertificateModal,
		showComplaintModal,
		closeComplaintModal,
		showMedicalCertificateModal,
		closeMedicalCertificateModal,
		setWorkstationsByWorker,
		getCidM,
	};

	if (isFetchWorkers) {
		return <Spinner />;
	}

	return (
		<ManagementContext.Provider value={context}>
			<Container>
				<Header />
				<Filter />
				<List />
				<ComplaintOrCertificate />
				<Complaint />
				<MedicalCertificate />
				<Create />
				<Update />
			</Container>
		</ManagementContext.Provider>
	);
}
