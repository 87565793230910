import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { WorkstationsDTO } from '@/components/ui/Inputs/types/workstation';
import { Workstation } from '@/types';

const getWorkstations = async ({ organization_id, company_id, line_id }: WorkstationsDTO): Promise<Workstation[]> => {
	const url = `/workstation/${organization_id}/${company_id}/${line_id}`;
	const { data } = await Api.get<Workstation[]>(url);
	return data;
};

export const useWorkstations = ({ organization_id, company_id, line_id }: WorkstationsDTO) => {
	const enabled = !!organization_id && !!company_id && !!line_id;

	const workstations = useQuery(
		['workstations', { organization_id, company_id, line_id }],
		() => getWorkstations({ organization_id, company_id, line_id }),
		{ enabled }
	);
	return workstations;
};
