import React, { useState, useContext, useEffect, useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { Row, Col, Form, Button, Table, message } from 'antd';
import moment from 'moment';

import Api from '@/services/api';
import { Title } from './Title';
import { AETContext } from '../context';

const { useForm } = Form;
const Container = Row;
const FormContent = Col;

export function Checklist() {
	const [loading, setLoading] = useState(false);

	const {
		companies,
		previousStep,
		company,
		fantasy_name,
		cnpj,
		cnae,
		risk_degree,
		company_activity,
		address,
		zipcode,
		district,
		city,
		state,
		listCompanies,
		reference_date,
		date_report,
		administrative_hours,
		operational_hours,
		direct_hourly,
		indirect_hourly,
		monthly_payer,
		volume_production,
		investments,
	} = useContext(AETContext);

	const [form] = useForm();
	const [data, setDate] = useState(null);
	const [selectedRows, setSelectedRows] = useState([]);

	const organization = useSelector((state) => state.organization.organization);

	const getCheckelists = useCallback(() => {
		if (!organization || companies?.length === 0) return;
		const urlParams = new URLSearchParams({ companies }).toString();
		Api.get(`/report/checklist/${organization.id}?${urlParams}`)
			.then((res) => setDate(res.data))
			.catch((err) => {})
			.finally(() => {});
	}, [organization, companies]);

	useEffect(() => {
		getCheckelists();
	}, [organization]);

	const columns = [
		{
			title: 'id',
			dataIndex: 'checklist_id',
			render: (text) => <a>{text.substring(0, 8)}</a>,
		},
		{
			title: I18n.get('File'),
			dataIndex: 'original_name',
			render: (text) => <a>{text}</a>,
		},
		{
			title: I18n.get('Date of colletion'),
			dataIndex: 'collection_date',
			render: (text) => <a>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</a>,
		},
	];

	function groupTwoElementsOP(array) {
		return array.reduce((acc, value) => {
			acc.push({
				round: value.round,
				init: moment(value.time[0]).format('HH:mm'),
				finish: moment(value.time[1]).format('HH:mm'),
			});
			return acc;
		}, []);
	}

	function groupTwoElementsADM(array) {
		return array.reduce((acc, value) => {
			acc.push({
				init: moment(value.time[0]).format('HH:mm'),
				finish: moment(value.time[1]).format('HH:mm'),
			});
			return acc;
		}, []);
	}

	function onFinish(values) {
		// let parameters = {
		// 	company: company,
		// 	fantasy_name: fantasy_name,
		// 	cnpj: cnpj,
		// 	cnae: cnae,
		// 	risk_degree: risk_degree,
		// 	company_activity: company_activity,
		// 	reference_date: moment(reference_date).format('DD-MM-YYYY'),
		// 	administrative_hours: groupTwoElements(administrative_hours),
		// 	operational_hours: groupTwoElements(operational_hours),
		// 	direct_hourly: direct_hourly,
		// 	indirect_hourly: indirect_hourly,
		// 	monthly_payer: monthly_payer,
		// 	volume_production: volume_production,
		// 	investiments: investiments,
		// 	checklists: selectedRows,
		// };

		/*
			Alter translate component for ConfigProvider;
		*/

		const application_organization = listCompanies
			.filter((item) => companies.includes(item.id) && item)
			.map((item) => ({ name: item.name }));

		let dateReport = moment(date_report).format('DD/MM/YYYY');
		let referenceDate = moment(reference_date).format('MMMM/YYYY');

		let [mounth, year] = referenceDate.split('/');
		referenceDate = `${I18n.get(mounth)}/${year}`;

		const getCheklists = data
			.filter((item) => selectedRows.includes(item.checklist_id) && item)
			.map((item) => ({ name: item.workstation_name, key: item.key }));

		const body = {
			application_organization: application_organization,
			organization: {
				company_name: company,
				fantasy_name: fantasy_name,
				cnpj: cnpj,
				address: address,
				city: city,
				state: state,
				zipcode: zipcode,
				district: district,
				cnae: cnae,
				risk_degree: risk_degree,
				company_activity: company_activity,
			},
			direct_hourly: direct_hourly,
			indirect_hourly: indirect_hourly,
			monthly_payer: monthly_payer,
			volume_production: volume_production,
			investments: investments,
			reference_date: referenceDate,
			date_report: dateReport,
			checklists: getCheklists,
			administrative_hours: groupTwoElementsADM(administrative_hours),
			operational_hours: groupTwoElementsOP(operational_hours),
		};

		Api.post('/aet/download', body)
			.then(setLoading(true))
			.then((res) => {
				let archive = document.createElement('a');
				archive.target = '_blank';
				archive.rel = 'noopener noreferrer';
				archive.href = res.data.url;
				archive.click();
				archive.remove();
			})
			.catch((error) => {
				const description = 'Failed to download AET report';
				message.error(I18n.get(description));
			})
			.finally(() => setLoading(false));
	}

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRowKeys);
		},
		getCheckboxProps: (record) => ({
			disabled: record.name === 'Disabled User',
			name: record.name,
		}),
	};

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			style={{ minHeight: '500px' }}
			// initialValues={{
			// 	direct_hourly: 0,
			// 	indirect_hourly: 0,
			// 	monthly_payer: 0,
			// }}
		>
			<Container>
				<Title>Checklist</Title>
				<FormContent sm={20}>
					<Row gutter={[0, 10]}>
						<Col sm={24}>
							<p>
								{I18n.get(
									'Include checklists related to this analysis below'
								)}
							</p>
						</Col>
						<Col sm={24}>
							<Table
								rowKey="checklist_id"
								rowSelection={{
									type: 'checkbox',
									...rowSelection,
								}}
								columns={columns}
								dataSource={data}
							/>
						</Col>
					</Row>
				</FormContent>
				<Col sm={24} style={{ position: 'absolute', bottom: 0 }}>
					<Row gutter={[5]}>
						<Col>
							<Button onClick={previousStep}>{I18n.get('Previous')}</Button>
						</Col>
						<Col>
							<Form.Item shouldUpdate noStyle>
								{() => (
									<Button
										type="primary"
										htmlType="submit"
										loading={loading}
									>
										{I18n.get('Done')}
									</Button>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Container>
		</Form>
	);
}
