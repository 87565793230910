import React, { useRef, useContext } from 'react';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS } from 'chart.js';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';

import { HealthMatrixContext } from '../context.js';

ChartJS.register(zoomPlugin);

export const CardChart = styled.div`
	padding: 25px;
	text-align: center;
	border-radius: 5px;
	background-color: white;
	box-shadow: 1px 1px 1px #888888;
	min-height: ${(props) => props.minHeight}vw;
	max-height: ${(props) => props.maxHeight}vw;
`;

export function BarChart({ orientation = 'x', minHeight = 10 }) {
	const { lines, selectBarOnClick } = useContext(HealthMatrixContext);

	const chartRef = useRef();

	const data = lines.data;

	const options = {
		skipNull: true,
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: orientation,

		scales: {
			x: {
				min: 0,
				max: 20,
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
					source: 'data',
					autoSkip: false,
				},
			},
			y: {
				min: 0,
				grid: {
					display: false,
				},
				ticks: {
					source: 'data',
					autoSkip: false,
					crossAlign: 'far', // Align labels
				},
			},
		},
		plugins: {
			datalabels: {
				display: false,
			},
			legend: {
				display: false,
			},
			zoom: {
				pan: {
					enabled: true,
					mode: 'x',
				},
			},
			title: {
				display: true,
				text: I18n.get('Score by line'),
			},
		},
	};

	return (
		<CardChart minHeight={minHeight}>
			<Bar
				ref={chartRef}
				options={options}
				onClick={(e) => selectBarOnClick(e, chartRef.current, data)}
				data={{
					labels: [...data?.map((item) => item.name)],
					datasets: [
						{
							borderWidth: 1,
							maxBarThickness: 40,
							label: I18n.get('Score'),
							backgroundColor: [
								...data?.map((item) => item.background),
							],
							borderColor: [...data?.map((item) => item.border)],
							data: [...data?.map((item) => item.score)],
						},
					],
				}}
			/>
		</CardChart>
	);
}
