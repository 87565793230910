import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Row, Col, DatePicker, Modal, Button, Form, Input, Select, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import Api from '@/services/api';
import { ComplaintsAndCertificatesContext } from '../context';

const { Option } = Select;

export function MedicalCertificate() {
	const {
		cidm,
		setErrors,
		closeEditModal,
		setTriggerReload,
		medical_certificate,
		certificateUpdateModal,
	} = useContext(ComplaintsAndCertificatesContext);

	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	const [form] = Form.useForm();

	const hasCertificate = useCallback(
		(payload) => {
			const { cidm, workstation, description, collection_date } = payload;
			const dateFormat = moment(collection_date);
			form.setFieldsValue({
				description,
				cidm: cidm.id,
				workstation: workstation.name,
				...(collection_date && { collection_date: dateFormat }),
			});
		},
		[form]
	);

	useEffect(() => {
		if (medical_certificate) {
			hasCertificate(medical_certificate);
		}
	}, [medical_certificate, hasCertificate]);

	function onClose() {
		closeEditModal();
	}

	async function onSubmit() {
		const values = await form.validateFields();
		onSave(values);
	}

	const Footer = () => [
		<Button key="cancel" onClick={onClose}>
			{I18n.get('Cancel')}
		</Button>,
		<Button key="submit" type="primary" onClick={onSubmit} loading={loadingSubmit}>
			{I18n.get('Save')}
		</Button>,
	];

	async function onSave(values) {
		try {
			setLoadingSubmit(true);

			const { description, cidm, collection_date } = values;
			const collectionDate = moment(collection_date).startOf('day').format();

			const payload = {
				cidm_id: cidm,
				description: description,
				organization_id: organizationId,
				collection_date: collectionDate,
			};

			const { data } = await Api.put(
				`/medical_certificate/${medical_certificate.id}?organization_id=${organizationId}`,
				payload
			);
			message.success(I18n.get(data.message));
			form.resetFields();
			closeEditModal();
			setTriggerReload();
		} catch (errors) {
			setErrors(errors);
		} finally {
			setLoadingSubmit(false);
		}
	}

	return (
		<Modal
			width={600}
			onOK={onSubmit}
			onCancel={onClose}
			footer={<Footer />}
			visible={certificateUpdateModal}
			title={I18n.get('Fill the information to be updated:')}
		>
			<Form form={form} layout="vertical" name="edit_medical_certificate">
				<Row>
					<Col sm={24}>
						<Form.Item
							name="cidm"
							label={I18n.get('CID-M')}
							rules={[
								{
									required: true,
									message: I18n.get('Select the CID-M'),
								},
							]}
						>
							<Select
								showSearch
								style={{ width: '100%' }}
								optionFilterProp="children"
								filterOption={(inputValue, option) =>
									option.children
										.join('')
										.toLowerCase()
										.includes(inputValue.toLowerCase())
								}
								placeholder={I18n.get('Select the CID-M')}
							>
								{cidm?.map((cidm, index) => (
									<Option key={index} value={cidm.id}>
										{cidm.code} - {cidm.description}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="collection_date"
							label={I18n.get('Date of colletion')}
							rules={[
								{
									message: I18n.get('Insert the date of collection'),
									required: true,
								},
							]}
						>
							<DatePicker
								format={'L'}
								placeholder={I18n.get('Date of colletion')}
								style={{ width: '100%' }}
								disabledDate={(current) =>
									current && current.valueOf() >= Date.now()
								}
							/>
						</Form.Item>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="description"
							label={I18n.get('Description')}
							rules={[
								{
									required: true,
									message: I18n.get('Please enter a valid description'),
								},
							]}
						>
							<Input.TextArea rows={4} maxLength={150} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
