import React, { ReactElement, useState } from 'react';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Select, Form, Skeleton, SelectProps } from 'antd';
import type { NamePath } from 'antd/lib/form/interface';

import { useApplicationContext } from '@/context/application';
import { useCreateSector } from '@/hooks/useSectorMutations';
import type { DefaultOptionType } from 'antd/lib/select';
import type { CreateSectorDTO } from '../types/sector';
import { DropdownRender } from '../../DropdownRender';
import type { Sector } from '@/types';

const { useFormInstance } = Form;

type SectorSelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	sectorId?: string;
	companyId?: string;
	fieldName: NamePath;
	allowCreation?: boolean;
	sectors: Sector[] | undefined;
};

type OptionType = { label: string; value: string } | undefined;

export function SectorSelect({
	sectors,
	loading,
	disabled,
	sectorId,
	companyId,
	size = 'middle',
	allowCreation = true,
	fieldName = 'sector_id',
	...props
}: SectorSelectProps & SelectProps) {
	const [sectorName, setSectorName] = useState('');

	const { company, organization } = useApplicationContext();
	const { validateFields, setFieldValue } = useFormInstance();

	const { mutateAsync: createSector, isLoading } = useCreateSector();

	async function onCreateSector() {
		const payload: CreateSectorDTO = {
			organization_id: organization?.id,
			company_id_creation: companyId ?? company?.id,
			company_id: company?.id,
			name: sectorName
		};

		const sector = await createSector({ payload });
		const sectorId = sector?.id;
		if (sectorId) {
			await validateFields();
			setFieldValue(fieldName, sectorId);
		}
		setSectorName('');
	}

	function handleSectorNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		const name = event.target.value;
		setSectorName(name);
	}

	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownrender(menu: ReactElement): JSX.Element {
		return (
			<DropdownRender
				menu={menu}
				name={sectorName}
				loading={isLoading}
				allowCreation={allowCreation}
				onClick={onCreateSector}
				onChange={handleSectorNameChange}
			/>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			{...props}
			allowClear
			showSearch
			size={size}
			value={sectorId}
			loading={loading}
			disabled={disabled}
			dropdownRender={(menu) => showDropdownrender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={sectors?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
