import { I18n } from '@aws-amplify/core';
import { Button, Divider, Input } from 'antd';
import React, { ReactElement } from 'react';

interface DropdownRenderProps {
	name: string;
	loading: boolean;
	menu: ReactElement;
	allowCreation: boolean;
	onClick: () => void;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DropdownRender: React.FC<DropdownRenderProps> = ({
	menu,
	name,
	loading,
	allowCreation,
	onClick,
	onChange
}) => {
	if (!allowCreation) {
		return <div>{menu}</div>;
	}
	return (
		<div>
			{menu}
			<Divider style={{ margin: '4px 0' }} />
			<div
				style={{
					display: 'flex',
					flexWrap: 'nowrap',
					padding: 8
				}}
			>
				<Input style={{ flex: 'auto', borderRadius: '7px' }} value={name} onChange={onChange} />
				<Button type="link" loading={loading} onClick={onClick} disabled={!name || name.length < 3}>
					{I18n.get('Add item')}
				</Button>
			</div>
		</div>
	);
};
