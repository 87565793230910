import React from 'react';

const defaultState = {
	currentStep: 0,
	company: null,
	fantasy_name: null,
	cnpj: null,
	cnae: null,
	risk_degree: null,
	company_activity: null,
	reference_date: null,
	date_report: null,
	address: null,
	zipcode: null,
	district: null,
	city: null,
	state: null,
	country: null,
	administrative_hours: [],
	operational_hours: [],
	direct_hourly: 0,
	indirect_hourly: 0,
	monthly_payer: 0,
	companies: [],
	volume_production: null,
	investments: null,
	listCompanies: null,
};

const defaultContext = {
	...defaultState,
	nextStep: () => {},
	previousStep: () => {},
	setData: () => {},
	setListCompanies: () => {},
};

export const AETContext = React.createContext(defaultContext);
