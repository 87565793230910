import moment from 'moment';

export function formatFileSize(size: number): string {
	if (size < 1024) return size + ' B';
	const i = Math.floor(Math.log(size) / Math.log(1024));
	const num = size / Math.pow(1024, i);
	const round = Math.round(num);
	const result = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
	return `${result} ${'KMGTPEZY'[i - 1]}B`;
}

export const convertSecondsToMinutes = (seconds: number): string | undefined => {
	if (!seconds) return undefined;
	return `${moment.unix(seconds).utc().format('mm:ss')}`;
};

export function documentMaskBRL(value: string): string {
	value = value.replace(/\D/g, '');

	if (value.length <= 11) {
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d)/, '$1.$2');
		value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	} else {
		value = value.replace(/^(\d{2})(\d)/, '$1.$2');
		value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
		value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
		value = value.replace(/(\d{4})(\d)/, '$1-$2');
	}

	return value;
}

export function formatSecondsDuration(seconds: number): string {
	const format = (val: number) => `0${Math.floor(val)}`.slice(-2);
	const hours = seconds / 3600;
	const minutes = (seconds % 3600) / 60;

	return [hours, minutes, seconds % 60].map(format).join(':');
}

export function secondsToDecimalMinutes(seconds?: number): number {
	if (seconds) {
		const minutes = (seconds / 60).toFixed(2);
		return parseFloat(minutes);
	}
	return 0;
}

export function decimalLocaleFormat(value: number, lang?: string): string {
	const formatted_value = value.toLocaleString(lang ?? 'en-US', {
		maximumFractionDigits: 2
	});
	return formatted_value;
}
