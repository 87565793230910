import { Text } from '@/components/Typography';
import styled from 'styled-components';

export const TextCustom = styled(Text)<{ $fontSize?: string; $bold?: boolean }>`
	font-weight: ${({ $bold }) => ($bold ? 'bold' : null)};
	font-size: ${({ $fontSize }) => $fontSize ?? 'unset'};
	color: #262626;
`;

export const DotCustom = styled(Text)`
	color: red;
`;
