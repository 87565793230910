import React from 'react';

const defaultContext = {
	lines: null,
	selectedLine: null,
	selectedLineSector: null,
	selectedLineCompany: null,
	lineHierarchy: null,
	startDate: null,
	endDate: null,
	selectBarOnClick: () => {},
	onClearFilter: () => {},
};

export const HealthMatrixContext = React.createContext(defaultContext);
