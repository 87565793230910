import React, { useContext } from 'react';
import { Collapse, Form, Row, Col, Button, Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';

import { ManagementContext } from './context';

export const Container = styled.div`
	padding-bottom: 10px;
	width: 100%;
`;

export function Filter() {
	const [form] = Form.useForm();

	const {
		companies,
		selectCompanyId,
		setSelectCompanyId,
		sectors,
		selectSectorId,
		setSelectSectorId,
		lines,
		selectLineId,
		setSelectLineId,
		workstations,
		// selectWorkstationId,
		setSelectWorkstationId,
		onFilterWorker
	} = useContext(ManagementContext);

	function resetFieldsForm(field) {
		const options = {
			company: ['sector', 'line', 'workstation'],
			sector: ['line', 'workstation'],
			line: ['workstation']
		};

		form.resetFields(options[field] || []);
	}

	function onClearFilter() {
		form.resetFields();
		setSelectCompanyId(null);
		setSelectSectorId(null);
		setSelectLineId(null);
		onFilterWorker();
	}

	async function handleOnSubmit() {
		const values = await form.validateFields();
		onFilterWorker(values);
	}

	return (
		<Container>
			<Collapse defaultActiveKey={['1']}>
				<Collapse.Panel header={I18n.get('Filters')} key="1">
					<Form form={form} layout="vertical" onFinish={handleOnSubmit}>
						<Row gutter={[10, 0]}>
							<Col xs={24} sm={6}>
								<Form.Item name="company" label={I18n.get('Industrial site')}>
									<Select
										showSearch
										onChange={(value) => {
											setSelectCompanyId(value);
											resetFieldsForm('company');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a industrial site')}
									>
										{companies.map((company) => (
											<Select.Option key={company.id} value={company.id}>
												{company.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="sector" label={I18n.get('Sector')}>
									<Select
										showSearch
										onChange={(value) => {
											setSelectSectorId(value);
											resetFieldsForm('sector');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										disabled={!selectCompanyId}
										placeholder={I18n.get('Select a sector')}
									>
										{sectors.map((sector) => (
											<Select.Option key={sector.id} value={sector.id}>
												{sector.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="line" label={I18n.get('Line')}>
									<Select
										showSearch
										onChange={(value) => {
											setSelectLineId(value);
											resetFieldsForm('line');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a line')}
										disabled={!selectSectorId}
									>
										{lines.map((line) => (
											<Select.Option key={line.id} value={line.id}>
												{line.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="workstation" label={I18n.get('Workstation')}>
									<Select
										showSearch
										onChange={setSelectWorkstationId}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a workstation')}
										disabled={!selectLineId}
									>
										{workstations.map((workstation) => (
											<Select.Option key={workstation.id} value={workstation.id}>
												{workstation.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={4}>
								<Form.Item
									name="register_id"
									label={I18n.get('Register')}
									rules={[
										{
											pattern: new RegExp(/^[0-9]+$/),
											message: I18n.get('Please enter a valid registration id')
										},
										{
											min: 7,
											message: I18n.get('The record id must be 7 characters long')
										}
									]}
								>
									<Input placeholder={I18n.get('Register')} maxLength={7} />
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="name" label={I18n.get('Name')}>
									<Input placeholder={I18n.get('Name')} />
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="pcd" label={I18n.get('PWD')}>
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select an option')}
										optionFilterProp="children"
									>
										<Select.Option value="true">{I18n.get('Yes')}</Select.Option>
										<Select.Option value="false">{I18n.get('No')}</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="gender" label={I18n.get('Gender')}>
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select an option')}
										optionFilterProp="children"
									>
										<Select.Option value="MALE">{I18n.get('Male')}</Select.Option>
										<Select.Option value="FEMALE">{I18n.get('Female')}</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item name="restrict" label={I18n.get('Restrict')}>
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select an option')}
										optionFilterProp="children"
									>
										<Select.Option value="TEMPORARY">{I18n.get('Temporary')}</Select.Option>
										<Select.Option value="INDETERMINATE">{I18n.get('Indeterminate')}</Select.Option>
										<Select.Option value="NOT_RESTRICT">{I18n.get('Not restrict')}</Select.Option>
										<Select.Option value="BOTH">
											{I18n.get('Temporary & Indeterminate')}
										</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={6} style={{ paddingTop: '20px' }}>
							<Col sm={3}>
								<Button
									type="primary"
									htmlType="submit"
									style={{ width: '100%' }}
									icon={<SearchOutlined />}
								>
									{I18n.get('Search')}
								</Button>
							</Col>
							<Col sm={3}>
								<Button onClick={onClearFilter} style={{ width: '100%' }}>
									{I18n.get('Clear')}
								</Button>
							</Col>
						</Row>
					</Form>
				</Collapse.Panel>
			</Collapse>
		</Container>
	);
}
