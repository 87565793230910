import React, { useContext } from 'react';
import { Row, Col, Steps } from 'antd';
import { I18n } from '@aws-amplify/core';
import { AETContext } from '../context';
import { Apresentation } from './Apresentation';
import { LocalApplication } from './LocalApplication';
import { ProductionVolume } from './ProductionVolume';
import { Investments } from './Investments';
import { Checklist } from './Checklist';

const Container = Col;
const { Step } = Steps;

export function Create() {
	const { currentStep } = useContext(AETContext);

	const steps = [
		{
			title: 'Apresentation',
			content: <Apresentation />
		},
		{
			title: 'Local application',
			content: <LocalApplication />
		},
		{
			title: 'Production volume',
			content: <ProductionVolume />
		},
		{
			title: 'Investments',
			content: <Investments />
		},
		{
			title: 'Checklist',
			content: <Checklist />
		}
	];

	const HeaderStep = () => (
		<Col sm={22}>
			<Steps size="small" current={currentStep}>
				{steps.map(({ title }, index) => (
					<Step key={index} title={I18n.get(title)} />
				))}
			</Steps>
		</Col>
	);

	const BodyStep = () => <Col sm={24}>{steps[currentStep].content}</Col>;

	return (
		<Container sm={16} xl={22}>
			<Row gutter={[0, 40]}>
				<HeaderStep />
				<BodyStep />
			</Row>
		</Container>
	);
}
