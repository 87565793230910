import React, { useEffect } from 'react';
import { Col, Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';

import { OperationsResponse } from '@/hooks/useOperations';
import type { TableValues } from '.';
import * as S from '../../../../components/styles';

interface SelectOperationProps {
	file: TableValues;
	loadingOperations?: boolean;
	operations?: OperationsResponse[];
	selectedRowKeys: React.Key[];
}

const { useFormInstance, useWatch } = Form;

export function TextOperation({ file, operations, loadingOperations, selectedRowKeys }: SelectOperationProps) {
	const { setFieldValue } = useFormInstance();
	const hasSelectedRow = selectedRowKeys.find((id) => id === file.id);
	const hasSelectedOperation = useWatch([file.id, 'operation_id']);

	function handleOperationError() {
		return Promise.reject(Error('Provide a operation'));
	}

	function handleSelectOperation(text: string) {
		if (!text) {
			return;
		}
		const operation = operations?.find(({ name }) => name.toLowerCase().trim() === text.toLowerCase().trim());
		setFieldValue([file.id, 'operation_id'], operation?.id);
		setFieldValue([file.id, 'operation_name'], text);
	}

	useEffect(() => {
		if (!operations?.length) {
			return;
		}
		if (hasSelectedOperation) {
			const operation = operations?.find(({ id }) => id === String(file?.operation_id));
			setFieldValue([file.id, 'operation_name'], operation?.name);
			return;
		}
		setFieldValue([file.id, 'operation_id'], file.operation_id);
	}, [file, setFieldValue, hasSelectedOperation, operations]);

	return (
		<S.ContainerInput $borderRadius="8px">
			<Col span={24}>
				<Form.Item name={[file.id, 'operation_id']} hidden />
				<Form.Item
					style={{ margin: 0 }}
					name={[file.id, 'operation_name']}
					rules={[
						({ getFieldValue }) => ({
							validator() {
								const value = getFieldValue([file.id, 'operation_name']);
								if (hasSelectedRow && !value) {
									return handleOperationError();
								}
							}
						})
					]}
				>
					<Input
						size="large"
						onBlur={(e) => handleSelectOperation(e.target.value)}
						disabled={!hasSelectedRow || !!file.operation_id || loadingOperations}
						placeholder={I18n.get('Operation')}
					/>
				</Form.Item>
			</Col>
		</S.ContainerInput>
	);
}
