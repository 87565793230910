import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Api from '@/services/api';
import { useAuth } from '@/hooks/useAuth';
import { setSession } from '@/redux/auth/actions';
import { Alerts, Logo, LoginForm } from './components';
import type { FormValues } from './components/Form';
import { Container } from './styles';

type Group = 'ehs' | 'engineering';
type UserGroups = Group[];

export function Login() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { isLoading, mutateAsync: getSession, isError, error } = useAuth();

	function handleRedirectUserByGroup(groups: UserGroups): void {
		const [group] = groups;
		history.push(`/${group}`);
	}

	async function onFinish(values: FormValues): Promise<void> {
		const data = await getSession(values);
		const { user, token } = data;
		Api.defaults.headers.Authorization = `Bearer ${token}`;
		localStorage.setItem('info_user', JSON.stringify({ user, token }));
		dispatch(setSession({ user, token }));
		handleRedirectUserByGroup(user.user_groups);
	}

	return (
		<Container>
			<Logo />
			{isError && <Alerts message={error.response?.data.message || error.message} />}
			<LoginForm isLoading={isLoading} onFinish={onFinish} />
		</Container>
	);
}
