import React, { useContext } from 'react';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { ManagementContext } from './context';
import { workerRestrictEnum } from '@/utils/enum';
import { Col, Button, Table, Space, Tooltip } from 'antd';

export function List() {
	const { workers, onDeleteWorker, showWorkerEditModal, showComplaintOrCertificateModal } =
		useContext(ManagementContext);

	const columns = [
		{
			title: I18n.get('Register'),
			dataIndex: 'register_id',
			key: 'register_id',
			align: 'center'
		},
		{
			title: I18n.get('Name'),
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: I18n.get('Restriction'),
			dataIndex: 'restrict',
			key: 'restrict',
			align: 'center',
			render: (text) => <span>{I18n.get(workerRestrictEnum[text])}</span>
		},
		{
			title: I18n.get('PWD'),
			dataIndex: 'pcd',
			key: 'pcd',
			align: 'center',
			render: (text) => <span>{I18n.get(text ? 'Yes' : 'No')}</span>
		},
		{
			title: I18n.get('Gender'),
			dataIndex: 'gender',
			key: 'gender',
			align: 'center',
			render: (text) => <span>{textParseGender(text)}</span>
		},
		{
			title: I18n.get('Activity'),
			dataIndex: 'is_active',
			key: 'is_active',
			align: 'center',
			render: (text) => <span>{I18n.get(text ? 'Active' : 'Disabled')}</span>
		},
		{
			title: I18n.get('Actions'),
			key: 'action',
			align: 'center',
			render: (worker) => (
				<Space size="middle">
					<Tooltip placement="bottomRight" title={I18n.get('Add complaint or certificate')}>
						<Button
							type="primary"
							onClick={() => showComplaintOrCertificateModal(worker)}
							icon={<Icons.FileAddOutlined />}
						/>
					</Tooltip>
					<Tooltip placement="bottomRight" title={I18n.get('Update worker')}>
						<Button
							type="primary"
							onClick={() => showWorkerEditModal(worker)}
							icon={<Icons.ToolOutlined />}
						/>
					</Tooltip>
					<Tooltip placement="bottomRight" title={I18n.get('Delete worker')}>
						<Button type="danger" onClick={() => onDeleteWorker(worker)} icon={<Icons.DeleteOutlined />} />
					</Tooltip>
				</Space>
			)
		}
	];

	function textParseGender(text) {
		const word = text.toLowerCase();
		const firstLatter = word[0].toUpperCase();
		const restWord = word.substring(1);
		return I18n.get(`${firstLatter + restWord}`);
	}

	return (
		<Col sm={24} md={24}>
			<Table
				columns={columns}
				dataSource={workers}
				pagination={{ pageSize: 10 }}
				rowKey={(record) => record.id}
			/>
		</Col>
	);
}
