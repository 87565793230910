import React from 'react';

const defaultState = {
	companies: null,
	selectCompanyId: null,
	sectors: null,
	selectSectorId: null,
	lines: null,
	selectLineId: null,
	workstations: null,
	selectWorkstationId: null,
	isFetchComplaints: true,
	isFetchCertificates: true,
	complaints: null,
	medical_certificates: null,
	triggerReload: null,
	errors: null,
	selectToggle: false,
	cidm: null,
	complaintUpdateModal: false,
	certificateUpdateModal: false,
	complaint: null,
	medical_certificate: null,
	workstationsByWorker: null,
};

const defaultContext = {
	...defaultState,
	setSelectCompanyId: () => {},
	setSelectSectorId: () => {},
	setSelectLineId: () => {},
	onFilter: () => {},
	setTriggerReload: () => {},
	setErrors: () => {},
	getCompanies: () => {},
	getSectors: () => {},
	getLines: () => {},
	getWorkstations: () => {},
	getComplaints: () => {},
	getCertificates: () => {},
	setSelectToggle: () => {},
	getCidM: () => {},
	showCertificateEditModal: () => {},
	showComplaintEditModal: () => {},
	closeEditModal: () => {},
	onDelete: () => {},
	setWorkstationsByWorker: () => {},
};

export const ComplaintsAndCertificatesContext = React.createContext(defaultContext);
