import React, { useContext } from 'react';
import * as Icons from '@ant-design/icons';
import { Col, Button, Table, Space, Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';

import { GenderEnum } from '@/utils/enum';
import { ComplaintsAndCertificatesContext } from './context';
import { workerRestrictEnum, bodyPartsComplaints } from '@/utils/enum';

export function List() {
	const { complaints, certificates, selectToggle, showCertificateEditModal, showComplaintEditModal, onDelete } =
		useContext(ComplaintsAndCertificatesContext);

	const onData = (selectToggle) => (selectToggle ? certificates : complaints);

	const cidM = {
		title: I18n.get('CID-M'),
		dataIndex: 'cidm',
		key: 'cidm',
		align: 'center',
		width: '15%',
		render: (cidm) => <span>{cidm.code}</span>
	};

	const bodyPart = {
		title: I18n.get('Body part'),
		dataIndex: 'body_part',
		key: 'body_part',
		align: 'center',
		width: '15%',
		render: (text) => {
			let hasPart = !!(text?.length > 0);
			let label = text?.map((item) => I18n.get(bodyPartsComplaints[item]))?.join(', ');
			return hasPart ? (
				<Tooltip title={label}>
					<Icons.InfoCircleOutlined />
				</Tooltip>
			) : (
				'-'
			);
		}
	};

	const onColumns = (selectToggle) => {
		const column = [
			{
				title: I18n.get('Register'),
				dataIndex: 'worker',
				key: 'worker',
				align: 'center',
				width: '8%',
				render: (worker) => <span>{worker.register_id}</span>
			},
			{
				title: I18n.get('Worker'),
				dataIndex: 'worker',
				key: 'worker',
				align: 'left',
				width: '15%',
				render: (worker) => <span>{worker.name}</span>
			},
			{
				title: I18n.get('Restrict'),
				dataIndex: 'worker',
				key: 'worker',
				align: 'center',
				width: '10%',
				render: (worker) => {
					const restrict = workerRestrictEnum[worker.restrict] || '-';
					return <span>{I18n.get(restrict)}</span>;
				}
			},
			{
				title: I18n.get('PWD'),
				dataIndex: 'worker',
				key: 'worker',
				align: 'center',
				width: '10%',
				render: (worker) => <span>{I18n.get(worker.pcd ? 'Yes' : 'No')}</span>
			},
			{
				title: I18n.get('Gender'),
				dataIndex: 'worker',
				key: 'worker',
				align: 'center',
				width: '10%',
				render: (worker) => {
					const gender = GenderEnum[worker.gender] || '-';
					return <span>{I18n.get(gender)}</span>;
				}
			},
			{
				title: I18n.get('Workstation'),
				dataIndex: 'workstation',
				key: 'workstation',
				align: 'center',
				width: '15%',
				render: (workstation) => <span>{workstation.name}</span>
			},
			selectToggle ? cidM : bodyPart,
			{
				title: I18n.get('Description'),
				dataIndex: 'description',
				key: 'description',
				align: 'center',
				width: '20%',
				render: (text) => text ?? '-'
			},
			{
				title: I18n.get('Date'),
				dataIndex: 'collection_date',
				key: 'collection_date',
				align: 'center',
				width: '10%',
				render: (date) => {
					if (!date) return '-';
					return new Date(date).toLocaleDateString('pt-BR');
				}
			},
			{
				title: I18n.get('Actions'),
				key: 'action',
				align: 'center',
				render: (record) => (
					<Space size="middle">
						<Tooltip
							placement="bottomRight"
							title={selectToggle ? I18n.get('Update medical certificate') : I18n.get('Update complaint')}
						>
							<Button
								type="primary"
								onClick={() =>
									selectToggle ? showCertificateEditModal(record) : showComplaintEditModal(record)
								}
								icon={<Icons.ToolOutlined />}
							/>
						</Tooltip>
						<Tooltip
							placement="bottomRight"
							title={selectToggle ? I18n.get('Delete medical certificate') : I18n.get('Delete complaint')}
						>
							<Button type="danger" onClick={() => onDelete(record)} icon={<Icons.DeleteOutlined />} />
						</Tooltip>
					</Space>
				)
			}
		];

		return column;
	};

	return (
		<Col sm={24} md={24}>
			<Table
				columns={onColumns(selectToggle)}
				dataSource={onData(selectToggle)}
				pagination={{ pageSize: 10 }}
				rowKey={(record) => record.id}
			/>
		</Col>
	);
}
