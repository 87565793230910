export enum QUERY_KEYS {
	GET_SECTORS = 'get-sectors',
	GET_MTM_CODE = 'get-mtm-code',
	GET_PRODUCTS = 'get-products',
	GET_MTM_CODES = 'get-mtm-codes',
	GET_MTM_REPORT = 'get-mtm-report',
	GET_MTM_REPORT_FILES = 'get-mtm-report-files',
	GET_MTM_TO_OPERATIONS = 'get-mtm-to-operations',
	GET_MTM_REPORT_RESULT = 'get-mtm-report-result',
	GET_AGGREGATION_VALUES = 'get-mtm-aggregation-values',
	GET_MTM_REPORT_OPERATIONS = 'get-mtm-report-operations',
	GET_FILES_WITH_OPERATIONS = 'get-files-with-operations',
	GET_BUSINESS_INFORMATIONS = 'get-business-informations',
	GET_MTM_REPORTS_PAGINATED = 'get-mtm-reports-paginated'
}
