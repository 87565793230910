import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';

import { BarChart } from './Chart/Bar';
import { HealthMatrixContext } from './context';

const Container = styled.div`
	position: absolute;
	right: 80px;
	top: 50px;
`;

export function MatrixChart() {
	const { onClearFilter, selectedLine } = useContext(HealthMatrixContext);

	const ClearButton = (props) => (
		<Container>
			<Button {...props}>{I18n.get('Clear filter')}</Button>
		</Container>
	);

	return (
		<Row gutter={[48, 24]} style={{ paddingBottom: '10px' }}>
			<Col sm={24}>
				{selectedLine && <ClearButton type="secondary" onClick={onClearFilter} />}
				<BarChart title="Score" attribute="score" minHeight={24} />
			</Col>
		</Row>
	);
}
