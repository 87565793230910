import React, { useContext, useState, useEffect } from 'react';
import * as Icons from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Modal, Row, Col } from 'antd';

import Spinner from '@/components/SpinnerDeprecated';
import { CardButton, CardLabel } from './styles';
import { ManagementContext } from '../context';
import Api from '@/services/api';

export function ComplaintOrCertificate() {
	const [isLoading, setIsLoading] = useState(false);
	const [workstationsWorker, setWorkstationsWorker] = useState(null);

	const style = { fontSize: '35px', opacity: '0.7' };

	const {
		worker,
		workstations,
		complaintOrCertificateModal,
		workstationsByWorker,
		closeComplaintOrCertificateModal,
		showComplaintModal,
		showMedicalCertificateModal,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setWorkstationsByWorker
	} = useContext(ManagementContext);

	const organizationId = useSelector(({ organization }) => organization.organization?.id);

	function optionTree({ id, name }) {
		return {
			title: name,
			value: id,
			key: id
		};
	}

	function onMountingTree(listWorker = [], workstations = []) {
		const canMountingTree = !complaintOrCertificateModal && !Array.isArray(workstations) && !workstations?.length;

		if (canMountingTree) return;

		if (!Array.isArray(listWorker) && !listWorker?.length) return;

		const treeValue = workstations.filter(({ id }) => listWorker.includes(id)).map(optionTree);

		setWorkstationsByWorker(treeValue);
	}

	function normalizeWorker({ company_id, sector_id, line_id, workstations }) {
		setSelectCompanyId(company_id);
		setSelectSectorId(sector_id);
		setSelectLineId(line_id);
		setWorkstationsWorker(workstations);
	}

	async function getWorker(worker) {
		if (!complaintOrCertificateModal || !organizationId || !worker) return;
		const url = `/worker/${worker.id}?organization_id=${organizationId}`;
		try {
			setIsLoading(true);
			const { data } = await Api.get(url);
			normalizeWorker(data);
		} catch (errors) {
			console.log(errors);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		getWorker(worker);
	}, [complaintOrCertificateModal]);

	useEffect(() => {
		onMountingTree(workstationsWorker, workstations);
	}, [workstations]);

	const Loading = () => (
		<div style={{ minHeight: '250px' }}>
			<Spinner />
		</div>
	);

	function showComplaint() {
		showComplaintModal(worker, workstationsByWorker);
	}

	function showCertificate() {
		showMedicalCertificateModal(worker, workstationsByWorker);
	}

	function handleOnCancel() {
		closeComplaintOrCertificateModal();
	}

	return (
		<Modal
			width={750}
			footer={null}
			destroyOnClose={true}
			onCancel={handleOnCancel}
			open={complaintOrCertificateModal}
			title={I18n.get('Select the desired option')}
		>
			{isLoading ? (
				<Loading />
			) : (
				<Row>
					<Col sm={24} style={{ padding: '10px 0px 10px 0px' }}>
						<Row gutter={[8, 8]} type="flex" justify="center" style={{ padding: '15px 30px 0px 30px' }}>
							<CardButton hoverable onClick={showComplaint}>
								<Icons.FrownOutlined style={style} />
								<CardLabel>{I18n.get('Complaint')}</CardLabel>
							</CardButton>
							<CardButton hoverable onClick={showCertificate}>
								<Icons.SnippetsOutlined style={style} />
								<CardLabel>{I18n.get('Medical Certificate')}</CardLabel>
							</CardButton>
						</Row>
					</Col>
				</Row>
			)}
		</Modal>
	);
}
