import React, { useMemo, useState } from 'react';
import { Col, Form, Popconfirm, Row, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import percentRound from 'percent-round';

import { MtmToOperationsResponse, useDeleteMtmToOperation, useMtmToOperations } from '@/hooks';
import { useMtmReportContext } from '@/components/views/MtmReport/context';
import { useCollapseContext } from '../../../components/Collapse/context';
import { ModalEditMtmItem } from '../Modals/ModalEditMtmItem';
import { Text } from '@/components/Typography';
import * as S from './styles';

interface MtmItemProps {
	operation_id?: string;
}

enum AggregationValueType {
	'VAA' = 'VAA',
	'SVAA' = 'SVAA',
	'NVAA' = 'NVAA'
}

export type MtmEditItem = Pick<
	MtmToOperationsResponse,
	| 'seconds'
	| 'sequence'
	| 'mtm_code'
	| 'quantity'
	| 'frequency'
	| 'description'
	| 'coefficient'
	| 'mtm_code_id'
	| 'mtm_aggregation_value'
	| 'mtm_aggregation_value_id'
> & {
	id: string;
};

export function MtmItems({ operation_id = '' }: MtmItemProps) {
	const { basicParams } = useMtmReportContext();
	const { collapseOpen } = useCollapseContext();
	const [editItem, setEditItem] = useState<MtmEditItem | undefined>(undefined);

	const { data: mtmToOperations, isFetching } = useMtmToOperations({
		operation_id: collapseOpen === operation_id ? operation_id : undefined,
		...basicParams
	});
	const { mutateAsync: deleteOperation } = useDeleteMtmToOperation();

	const handleDeleteClick = async (id: string) => {
		await deleteOperation({
			...basicParams,
			id
		});
	};

	const handleEditClick = async (item: MtmEditItem) => {
		setEditItem(item);
	};

	const handleCloseModal = async () => {
		setEditItem(undefined);
	};

	if (isFetching && !mtmToOperations?.length) {
		return (
			<Row>
				<Col span={24}>
					<Skeleton.Input active block style={{ marginBottom: 10 }} />
				</Col>
				<Col span={24}>
					<Skeleton.Input active block style={{ marginBottom: 10 }} />
				</Col>
			</Row>
		);
	}

	const aggregationValueResult = mtmToOperations?.reduce(
		(acc, item) => {
			const { mtm_aggregation_value } = item;

			const type = mtm_aggregation_value.type;

			acc[type] += Number(item.seconds);
			acc.total_time += Number(item.seconds);
			return acc;
		},
		{
			VAA: 0,
			SVAA: 0,
			NVAA: 0,
			total_time: 0
		}
	);

	function fixedPercentage(type: AggregationValueType) {
		if (!aggregationValueResult?.[type]) {
			return '0%';
		}
		const total = aggregationValueResult?.total_time || 0;
		const vaa_percentage = (aggregationValueResult.VAA / total) * 100;
		const svaa_percentage = (aggregationValueResult.SVAA / total) * 100;
		const nvaa_percentage = (aggregationValueResult.NVAA / total) * 100;

		const type_index = {
			VAA: 0,
			SVAA: 1,
			NVAA: 2
		};

		const fixed_percents = percentRound([vaa_percentage, svaa_percentage, nvaa_percentage], 2);

		const aggregation_value_percent = Number(fixed_percents[type_index[type]]) / 100;

		const formatted_percent = aggregation_value_percent.toLocaleString(navigator.language, {
			style: 'percent',
			maximumFractionDigits: 2
		});

		return formatted_percent;
	}

	return (
		<Row>
			{mtmToOperations?.map(
				(
					{
						id,
						seconds,
						mtm_code,
						quantity,
						sequence,
						frequency,
						description,
						coefficient,
						mtm_code_id,
						mtm_aggregation_value,
						mtm_aggregation_value_id
					},
					i
				) => (
					<S.MtmItem span={24}>
						<Form.Item name={['mtm_to_operation', operation_id, id]} key={id} style={{ marginBottom: 10 }}>
							{i === 0 && (
								<Row className="labels">
									<Col span={1}>
										<Text className="bold">N°</Text>
									</Col>
									<Col xxl={11} xs={8}>
										<Text className="bold">Description</Text>
									</Col>
									<Col span={3}>
										<Text className="bold">Code</Text>
									</Col>
									<Col span={1}>
										<Text className="bold">Q</Text>
									</Col>
									<Col span={1}>
										<Text className="bold">F</Text>
									</Col>
									<Col span={1}>
										<Text className="bold">C</Text>
									</Col>
									<Col xxl={2} xs={3}>
										<Text className="bold">VA</Text>
									</Col>
									<Col xxl={2} xs={3}>
										<Text className="bold">Time</Text>
									</Col>
									<Col xxl={2} xs={3}>
										&nbsp;
									</Col>
								</Row>
							)}
							<Row className="item-border">
								<Col span={1} className="bg-blue no-border-left table-item">
									<Text>{sequence ?? '-'}</Text>
								</Col>
								<Col xxl={11} xs={8} className="no-border-left no-center table-item">
									<Text style={{ marginLeft: 10, marginRight: 10 }}>{I18n.get(description)}</Text>
								</Col>
								<Col span={3} className="table-item">
									<Text style={{ paddingLeft: 10, paddingRight: 10 }}>{mtm_code.code}</Text>
								</Col>
								<Col span={1} className="table-item">
									<Text>{quantity}</Text>
								</Col>
								<Col span={1} className="table-item">
									<Text>{frequency}</Text>
								</Col>
								<Col span={1} className="table-item">
									<Text>{coefficient}</Text>
								</Col>
								<Col xxl={2} xs={3} className="table-item">
									<Text className={mtm_aggregation_value.type}>{mtm_aggregation_value.type}</Text>
								</Col>
								<Col xxl={2} xs={3} className="seconds table-item">
									<Text>{Number(seconds)}s</Text>
								</Col>
								<Col xxl={2} xs={3} className="buttons table-item">
									<EditOutlined
										className="primary"
										onClick={() =>
											handleEditClick({
												id,
												seconds: mtm_code.seconds as number,
												mtm_code,
												quantity,
												sequence,
												frequency,
												description,
												coefficient,
												mtm_code_id,
												mtm_aggregation_value,
												mtm_aggregation_value_id
											})
										}
									/>

									<Popconfirm
										onConfirm={() => handleDeleteClick(id)}
										title={<Text>Are you sure?</Text>}
										icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
									>
										<DeleteOutlined className="error" />
									</Popconfirm>
								</Col>
							</Row>
						</Form.Item>
					</S.MtmItem>
				)
			)}
			{!!aggregationValueResult?.total_time && (
				<Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
					<S.AggregationValue>
						<div className="label VAA">
							<Text>VAA</Text>
						</div>
						<div className="value">
							<Text>{fixedPercentage(AggregationValueType.VAA)}</Text>
						</div>
					</S.AggregationValue>
					<S.AggregationValue>
						<div className="label SVAA">
							<Text>SVAA</Text>
						</div>
						<div className="value">
							<Text>{fixedPercentage(AggregationValueType.SVAA)}</Text>
						</div>
					</S.AggregationValue>
					<S.AggregationValue>
						<div className="label NVAA">
							<Text>NVAA</Text>
						</div>
						<div className="value">
							<Text>{fixedPercentage(AggregationValueType.NVAA)}</Text>
						</div>
					</S.AggregationValue>
					<S.AggregationValue className="total-time">
						<div className="label bg-blue">
							<Text>Total time (min)</Text>
						</div>
						<div className="value">
							<Text>
								{(aggregationValueResult.total_time / 60).toLocaleString(navigator.language, {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2
								})}
							</Text>
						</div>
					</S.AggregationValue>
				</Col>
			)}
			<ModalEditMtmItem
				item={editItem}
				operation_id={operation_id}
				open={!!editItem}
				onClose={() => handleCloseModal()}
			/>
		</Row>
	);
}
