import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { I18n } from '@aws-amplify/core';

import { ManageOrganizationsService } from './service';
import {
	CompanyDTO,
	CreateOrganizationRequest,
	CreateRequest,
	DeleteOrganizationRequest,
	DeleteRequest,
	LineDTO,
	OrganizationDTO,
	SectorDTO,
	UpdateOrganizationRequest,
	UpdateRequest,
	WorkstationDTO
} from './types';

const manageOrganizationsService = new ManageOrganizationsService();

function useGetOrganizations() {
	const organizations = useQuery(['organizations_list'], () => manageOrganizationsService.fetchOrganizations());
	return {
		...organizations,
		data: organizations.data || ([] as OrganizationDTO[])
	};
}

function useGetCompanies(organizationId: string) {
	const companies = useQuery(
		['companies_list', organizationId],
		() => manageOrganizationsService.fetchCompanies(organizationId),
		{
			enabled: !!organizationId
		}
	);
	return {
		...companies,
		data: companies.data || ([] as CompanyDTO[])
	};
}

function useGetSectors(organizationId: string, companyId: string) {
	const sectors = useQuery(
		['sectors_list', organizationId, companyId],
		() => manageOrganizationsService.fetchSectors(organizationId, companyId),
		{
			enabled: !!companyId
		}
	);
	return {
		...sectors,
		data: sectors.data || ([] as SectorDTO[])
	};
}

function useGetLines(organizationId: string, companyId: string, sectorId: string) {
	const lines = useQuery(
		['lines_list', organizationId, companyId, sectorId],
		() => manageOrganizationsService.fetchLines(organizationId, companyId, sectorId),
		{
			enabled: !!sectorId
		}
	);
	return {
		...lines,
		data: lines.data || ([] as LineDTO[])
	};
}

function useGetWorkstations(organizationId: string, companyId: string, lineId: string) {
	const workstations = useQuery(
		['workstations_list', organizationId, companyId, lineId],
		() => manageOrganizationsService.fetchWorkstations(organizationId, companyId, lineId),
		{
			enabled: !!lineId
		}
	);
	return {
		...workstations,
		data: workstations.data || ([] as WorkstationDTO[])
	};
}

function useCreateOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: CreateOrganizationRequest) => manageOrganizationsService.createOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([queryClient.invalidateQueries(['organizations_list'])]);
			message.success(I18n.get('Organization created successfully'));
		}
	});
}

function useUpdateOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateOrganizationRequest) => manageOrganizationsService.updateOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([queryClient.invalidateQueries(['organizations_list'])]);
			message.success(I18n.get('Organization updated successfully'));
		}
	});
}

function useDeleteOrganization() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteOrganizationRequest) => manageOrganizationsService.deleteOrganization(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([queryClient.invalidateQueries(['organizations_list'])]);
			message.success(I18n.get('Organization deleted successfully'));
		}
	});
}

function useCreate() {
	const queryClient = useQueryClient();
	return useMutation((body: CreateRequest) => manageOrganizationsService.create(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['companies_list']),
				queryClient.invalidateQueries(['sectors_list']),
				queryClient.invalidateQueries(['lines_list']),
				queryClient.invalidateQueries(['workstations_list']),
				queryClient.invalidateQueries(['products_list'])
			]);
			message.success(I18n.get('Data created successfully'));
		}
	});
}

function useUpdate() {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateRequest) => manageOrganizationsService.update(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['companies_list']),
				queryClient.invalidateQueries(['sectors_list']),
				queryClient.invalidateQueries(['lines_list']),
				queryClient.invalidateQueries(['workstations_list']),
				queryClient.invalidateQueries(['products_list'])
			]);
			message.success(I18n.get('Data updated successfully'));
		}
	});
}

function useDelete() {
	const queryClient = useQueryClient();
	return useMutation((body: DeleteRequest) => manageOrganizationsService.delete(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message || err.response.statusText));
		},
		onSuccess: () => {
			Promise.all([
				queryClient.invalidateQueries(['organizations_list']),
				queryClient.invalidateQueries(['companies_list']),
				queryClient.invalidateQueries(['sectors_list']),
				queryClient.invalidateQueries(['lines_list']),
				queryClient.invalidateQueries(['workstations_list']),
				queryClient.invalidateQueries(['products_list'])
			]);
			message.success(I18n.get('Data deleted successfully'));
		}
	});
}

export default {
	useGetOrganizations,
	useGetCompanies,
	useGetSectors,
	useGetLines,
	useGetWorkstations,
	useCreateOrganization,
	useUpdateOrganization,
	useDeleteOrganization,
	useCreate,
	useUpdate,
	useDelete
};
