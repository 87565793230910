import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Skeleton } from 'antd';

import { Title } from './Title';
import { Create } from './Create';
import { AETContext } from './context';
import { useApplicationContext } from '@/context/application';
import { useGetBusinessInformations } from '@/hooks/useGetBusinessInformations';

const Container = Row;

export function ErgonomicAnalysisWork() {
	const { organization, company } = useApplicationContext();
	const [state, setState] = useState({
		currentStep: 0,
		company: '',
		fantasy_name: '',
		cnpj: '',
		cnae: '',
		company_activity: '',
		reference_date: null,
		date_report: null,
		address: '',
		zipcode: '',
		district: '',
		city: '',
		state: '',
		country: '',
		administrative_hours: [],
		operational_hours: [],
		direct_hourly: 0,
		indirect_hourly: 0,
		monthly_payer: 0,
		companies: [],
		volume_production: null,
		investments: null,
		listCompanies: null
	});

	const { isLoading, data: businessInformations, isError } = useGetBusinessInformations(organization.id, company.id);

	let administraiveTurns = [];
	let operationalTurns = [];

	businessInformations?.work_hours?.forEach((work_hour) => {
		if (work_hour.sector_type === 'ADMINISTRATIVE') {
			administraiveTurns.push({
				time: [moment(work_hour.start_time, 'HH:mm'), moment(work_hour.end_time, 'HH:mm')]
			});
		} else {
			operationalTurns.push({
				round: parseInt(work_hour.work_shift),
				time: [moment(work_hour.start_time, 'HH:mm'), moment(work_hour.end_time, 'HH:mm')]
			});
		}
	});
	operationalTurns.sort((a, b) => a.round - b.round);

	useEffect(() => {
		if (!isLoading && !isError && !state.company) {
			setState({
				company: businessInformations?.company_name,
				administrative_hours: administraiveTurns,
				operational_hours: operationalTurns,
				...businessInformations,
				volume_production: null,
				reference_date: null,
				listCompanies: null,
				indirect_hourly: 0,
				investments: null,
				date_report: null,
				direct_hourly: 0,
				monthly_payer: 0,
				currentStep: 0,
				companies: []
			});
		}
	}, [isLoading, isError, businessInformations]);

	function nextStep() {
		setState((prev) => ({ ...prev, currentStep: prev.currentStep + 1 }));
	}

	function previousStep() {
		setState((prev) => ({ ...prev, currentStep: prev.currentStep - 1 }));
	}

	function setListCompanies(data) {
		setState((prev) => ({ ...prev, listCompanies: data }));
	}

	function setData(data) {
		setState((prev) => ({ ...prev, ...data }));
	}

	const context = {
		...state,
		nextStep,
		previousStep,
		setData,
		setListCompanies
	};

	if (isLoading) {
		return (
			<>
				<Row>
					<Skeleton active />
				</Row>
				<Row>
					<Skeleton active />
				</Row>
				<Row>
					<Skeleton active />
				</Row>
				<Row>
					<Skeleton active />
				</Row>
			</>
		);
	}

	return (
		<AETContext.Provider value={context}>
			<Container>
				<Title>Ergonomic analysis of work</Title>
				<Create />
			</Container>
		</AETContext.Provider>
	);
}
