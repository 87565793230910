import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { RequiredLabel } from '@/components/ui/RequiredLabel';
import { useCompanies } from '@/hooks';
import * as S from '../styles';

const { useFormInstance, useWatch } = Form;

interface CompanyProps {
	namePlace: string;
	fontSize?: string;
	isRequired?: boolean;
}

export function Company({ namePlace, fontSize, isRequired = false }: CompanyProps) {
	const organizationId = useWatch([namePlace, 'organization_id']);
	const companyId = useWatch([namePlace, 'company_id']);

	const { data, isFetching } = useCompanies(organizationId);

	const { setFieldValue } = useFormInstance();

	function handleValue(id: string) {
		setFieldValue([namePlace, 'company_id'], id);
		setFieldValue([namePlace, 'line_id'], undefined);
		setFieldValue([namePlace, 'sector_id'], undefined);
	}

	function handleClear() {
		setFieldValue([namePlace, 'line_id'], undefined);
		setFieldValue([namePlace, 'sector_id'], undefined);
	}

	return (
		<Col span={23}>
			<Form.Item
				required={false}
				labelCol={{ span: 24 }}
				name={[namePlace, 'company_id']}
				rules={[{ required: true, message: I18n.get('Select the industrial site') }]}
				label={<RequiredLabel fontSize={fontSize} title="Industrial Site" isRequired={isRequired} />}
			>
				<S.ContainerSelect $borderRadius="8px">
					<CompanySelect
						size="large"
						companies={data}
						loading={false}
						disabled={true}
						companyId={companyId}
						onClear={handleClear}
						onChange={handleValue}
						fieldName={[namePlace, 'company_id']}
					/>
				</S.ContainerSelect>
			</Form.Item>
		</Col>
	);
}
