import React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, message, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { Header } from './Header';
import { Filter } from './Filter';
import { List } from './List';
import { Complaint } from './Complaint';
import { MedicalCertificate } from './MedicalCertificate';

import { ComplaintsAndCertificatesContext } from './context';

import Api from '@/services/api';
import Spinner from '@/components/SpinnerDeprecated';
import { useIsMounted } from '@/hooks/useIsMounted';

const initState = {
	companies: [],
	selectCompanyId: '',
	sectors: [],
	selectSectorId: '',
	lines: [],
	selectLineId: '',
	workstations: [],
	selectWorkstationId: '',
	isFetchComplaints: true,
	isFetchCertificates: true,
	triggerReload: false,
	errors: null,
	complaints: [],
	certificates: [],
	selectToggle: false,
	cidm: [],
	complaintUpdateModal: false,
	certificateUpdateModal: false,
	workstationsByWorker: null,
};

export function ComplaintsAndCertificates() {
	const [state, setState] = useState(initState);

	const isMounted = useIsMounted();

	const { type, companyId, sectorId, lineId } = useParams();
	const redirected = type ? true : false;

	const organizationId = useSelector(
		({ organization }) => organization.organization?.id
	);

	function setSelectCompanyId(value) {
		setState((prev) => ({
			...prev,
			selectCompanyId: value,
			selectSectorId: null,
			selectLineId: null,
		}));
	}

	function setSelectSectorId(value) {
		setState((prev) => ({
			...prev,
			selectSectorId: value,
			selectLineId: null,
		}));
	}

	function setSelectLineId(value) {
		setState((prev) => ({ ...prev, selectLineId: value }));
	}

	function setSelectWorkstationId(value) {
		setState((prev) => ({ ...prev, selectWorkstationId: value }));
	}

	async function onFilter(values) {
		if (!organizationId) return;

		const {
			company,
			sector,
			line,
			workstation,
			register_id,
			name,
			pcd,
			gender,
			restrict,
			description,
			body_part,
			cidm_id,
			start_date,
			end_date,
		} = values || {};

		let url = state.selectToggle ? `/medical_certificate?` : `/complaint?`;

		const startOfDay = start_date
			? new Date(start_date).setUTCHours(0, 0, 0, 0)
			: null;
		const endOfDay = end_date
			? new Date(end_date).setUTCHours(23, 59, 59, 999)
			: null;

		const params = {
			organization_id: organizationId,
			...(company && { company_id: company }),
			...(sector && { sector_id: sector }),
			...(line && { line_id: line }),
			...(workstation && { workstation_id: workstation }),
			...(register_id && { register_id }),
			...(name && { name }),
			...(pcd && { pcd }),
			...(gender && { gender }),
			...(restrict && { restrict }),
			...(description && { description }),
			...(body_part && { body_part }),
			...(cidm_id && { cidm_id }),
			...(startOfDay && { start_date: startOfDay }),
			...(endOfDay && { end_date: endOfDay }),
		};

		console.log({ params });

		const paramsMounting = new URLSearchParams(params);

		url += paramsMounting.toString();

		if (state.selectToggle) {
			try {
				const { data: certificates } = await Api.get(url);
				isMounted() && setState((prev) => ({ ...prev, certificates }));
			} catch (errors) {
				isMounted() && setState((prev) => ({ ...prev, errors }));
			} finally {
				setState((prev) => ({ ...prev, isFetchCertificates: false }));
			}
		} else {
			try {
				const { data: complaints } = await Api.get(url);
				isMounted() && setState((prev) => ({ ...prev, complaints }));
			} catch (errors) {
				isMounted() && setState((prev) => ({ ...prev, errors }));
			} finally {
				setState((prev) => ({ ...prev, isFetchComplaints: false }));
			}
		}
	}

	function setSelectToggle() {
		setState((prev) => ({
			...prev,
			selectToggle: !prev.selectToggle,
		}));
	}

	function setTriggerReload() {
		setState((prev) => ({ ...prev, triggerReload: !prev.triggerReload }));
	}

	function setErrors(errors) {
		setState((prev) => ({ ...prev, errors }));
	}

	async function getCompanies(organizationId) {
		if (!organizationId) return;
		let url = `/company/user_list/${organizationId}`;
		try {
			const { data: companies } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, companies }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getSectors(organizationId, companyId) {
		if (!organizationId || !companyId) return;
		let url = `/sector/${organizationId}/${companyId}`;
		try {
			const { data: sectors } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, sectors }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getLines(organizationId, companyId, sectorId) {
		if (!organizationId || !companyId || !sectorId) return;
		let url = `/line/${organizationId}/${companyId}/${sectorId}`;
		try {
			const { data: lines } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, lines }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getWorkstations(organizationId, companyId, sectorId, lineId) {
		if (!organizationId || !companyId || !sectorId || !lineId) return;
		let url = `/workstation/${organizationId}/${companyId}/${lineId}`;
		try {
			const { data: workstations } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, workstations }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	async function getComplaints(organizationId) {
		if (!organizationId) return;
		let url = `/complaint/?organization_id=${organizationId}`;
		try {
			const { data: complaints } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, complaints }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		} finally {
			isMounted() && setState((prev) => ({ ...prev, isFetchComplaints: false }));
		}
	}

	async function getCertificates(organizationId) {
		if (!organizationId) return;
		let url = `/medical_certificate/?organization_id=${organizationId}`;
		try {
			const { data: certificates } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, certificates }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		} finally {
			isMounted() && setState((prev) => ({ ...prev, isFetchCertificates: false }));
		}
	}

	async function getCidM(organizationId) {
		if (!organizationId) return;
		let url = `/medical_certificate/cidm/?organization_id=${organizationId}`;
		try {
			const { data: cidm } = await Api.get(url);
			isMounted() && setState((prev) => ({ ...prev, cidm }));
		} catch (errors) {
			isMounted() && setState((prev) => ({ ...prev, errors }));
		}
	}

	function showCertificateEditModal(medical_certificate, workstationsByWorker) {
		setState((prev) => ({
			...prev,
			certificateUpdateModal: true,
			medical_certificate: medical_certificate,
			workstationsByWorker,
		}));
	}

	function showComplaintEditModal(complaint, workstationsByWorker) {
		setState((prev) => ({
			...prev,
			complaintUpdateModal: true,
			complaint: complaint,
			workstationsByWorker,
		}));
	}

	function closeEditModal() {
		if (context.selectToggle) {
			setState((prev) => ({ ...prev, certificateUpdateModal: false }));
		}
		if (!context.selectToggle) {
			setState((prev) => ({ ...prev, complaintUpdateModal: false }));
		}
	}

	function setWorkstationsByWorker(workstationsByWorker) {
		setState((prev) => ({ ...prev, workstationsByWorker }));
	}

	function onDelete({ id }) {
		Modal.confirm({
			title: I18n.get('Warning'),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('No'),
			icon: <ExclamationCircleOutlined />,
			content: context.selectToggle
				? I18n.get('Do you want to delete this medical certificate?')
				: I18n.get('Do you want to delete this complaint?'),
			onOk() {
				return new Promise(async (resolve, reject) => {
					const url = context.selectToggle
						? `/medical_certificate/${id}?organization_id=${organizationId}`
						: `/complaint/${id}?organization_id=${organizationId}`;
					try {
						const { data } = await Api.delete(url);
						resolve();
						message.success(I18n.get(data.message));
						setTriggerReload();
					} catch (errors) {
						setState((prev) => ({ ...prev, errors }));
						reject();
					}
				});
			},
		});
	}

	const {
		selectCompanyId,
		selectSectorId,
		selectLineId,
		isFetchComplaints,
		isFetchCertificates,
		triggerReload,
		errors,
	} = state;

	useEffect(() => {
		getCompanies(organizationId);
		if (!redirected || (redirected && type === 'certificate'))
			getComplaints(organizationId);
		if (!redirected || (redirected && type === 'complaint'))
			getCertificates(organizationId);
		getCidM(organizationId);
	}, [triggerReload, organizationId]);

	useEffect(() => {
		getSectors(organizationId, selectCompanyId);
	}, [selectCompanyId]);

	useEffect(() => {
		getLines(organizationId, selectCompanyId, selectSectorId);
	}, [selectCompanyId, selectSectorId]);

	useEffect(() => {
		getWorkstations(organizationId, selectCompanyId, selectSectorId, selectLineId);
	}, [selectCompanyId, selectSectorId, selectLineId]);

	useEffect(() => {
		if (!companyId && !sectorId && !lineId) return;
		if (type === 'certificate' && !state.selectToggle) {
			setSelectToggle();
			return;
		}

		const urlParams = {
			company: companyId,
			sector: sectorId,
			line: lineId,
		};
		onFilter(urlParams);
	}, [type]);

	// Used only because of the asynchronous setSelectToggle function, so we have to wait for selectToggle to change
	useEffect(() => {
		if (type !== 'certificate' || (!companyId && !sectorId && !lineId)) return;
		const urlParams = {
			company: companyId,
			sector: sectorId,
			line: lineId,
		};
		onFilter(urlParams);
	}, [state.selectToggle]);

	useEffect(() => {
		if (errors) {
			const result = errors.response?.data?.message || errors.message;
			const description = I18n.get(result);
			message.error(description);
			setErrors(null);
		}
	}, [errors]);

	const context = {
		...state,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setSelectWorkstationId,
		onFilter,
		setTriggerReload,
		setErrors,
		getCompanies,
		getSectors,
		getLines,
		getWorkstations,
		setSelectToggle,
		getCidM,
		showCertificateEditModal,
		showComplaintEditModal,
		closeEditModal,
		onDelete,
		setWorkstationsByWorker,
	};

	if (isFetchComplaints || isFetchCertificates) {
		return <Spinner />;
	}

	return (
		<ComplaintsAndCertificatesContext.Provider value={context}>
			<Row>
				<Header />
				<Filter />
				<List />
				<Complaint />
				<MedicalCertificate />
			</Row>
		</ComplaintsAndCertificatesContext.Provider>
	);
}
