import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useParams } from 'react-router-dom';

import Api from '@/services/api';
import { useSelector } from 'react-redux';
import { SingleCassificationList } from './SingleCassificationList';

export function ResumeEffort({ data }) {
	const query = useParams();

	const checklist = useSelector((state) => state.checklist.checklist);
	const company = useSelector((state) => state.organization.company);
	const organization = useSelector((state) => state.organization.organization);
	const file = useSelector((state) => state.checklist.file);

	const [efforts, setEfforts] = React.useState({
		loading: false,
		data: null,
		err: null,
	});

	function getParameters() {
		let body = {};

		if (query.organization_id) {
			body['organization_id'] = query.organization_id;
		}

		if (query.organization_id && query.company_id) {
			body['company_id'] = query.company_id;
		}

		if (query.file_id) {
			body['file_id'] = query.file_id;
			body['checklist_id'] = checklist.id;
		}

		return body;
	}

	const getEfforts = React.useCallback(() => {
		if (!organization || !company || !checklist || !file) return;

		let body = getParameters();

		if (Object.keys(body).length === 0) {
			body = {
				organization_id: organization.id,
				company_id: company.id,
				checklist_id: checklist.id,
				file_id: file.id,
			};
		}

		Api.post('/report/checklist/effort/all', body)
			.then((res) => {
				setEfforts((prev) => ({ ...prev, data: res.data }));
			})
			.catch((err) => {});
	}, [organization, company, checklist, file]);

	React.useEffect(() => {
		getEfforts();
	}, [organization, company, checklist, file]);

	return (
		<SingleCassificationList
			classification={data?.effort?.note}
			title={I18n.get('Effort')}
			frequencyUnity={I18n.get(' x per hour')}
			items={efforts.data?.map((item) => ({
				name: item.name,
				effort: item.weight.toLocaleString(),
				frequency: item.frequency,
			}))}
		/>
	);
}
