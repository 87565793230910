import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { LinesDTO } from '@/components/ui/Inputs/types/line';
import { Line } from '@/types';

const getLines = async ({ organization_id, company_id, sector_id }: LinesDTO): Promise<Line[]> => {
	const url = `/line/${organization_id}/${company_id}/${sector_id}`;
	const { data } = await Api.get<Line[]>(url);
	return data;
};

export const useLines = ({ organization_id, company_id, sector_id }: LinesDTO) => {
	const enabled = !!organization_id && !!company_id && !!sector_id;

	const lines = useQuery(
		['lines', { organization_id, company_id, sector_id }],
		() => getLines({ organization_id, company_id, sector_id }),
		{ enabled }
	);
	return lines;
};
