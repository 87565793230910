import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, notification } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CreateSectorDTO } from '@/components/ui/Inputs/types/sector';
import { Sector } from '@/types';
import Api from '@/services/api';

const createSector = async (body: CreateSectorDTO): Promise<Sector> => {
	const url = `/sector/create`;
	const { data } = await Api.post<Sector>(url, body);
	return data;
};

export const useCreateSector = () => {
	const queryClient = useQueryClient();
	return useMutation(({ payload }: { payload: CreateSectorDTO }) => createSector(payload), {
		onError: (err: any) => {
			notification.error({
				message: I18n.get(err?.response?.data?.message || 'Ops... something happened!')
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['sectors']);
			queryClient.invalidateQueries(['sectors_list']);
			message.success(I18n.get('Sector created successfully'));
		}
	});
};
