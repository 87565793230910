import React from 'react';
import { TreeSelect } from 'antd';

const { SHOW_PARENT } = TreeSelect;

export function TreePermissions({ value = [], onChange, tree, placeholder }) {
	const tProps = {
		treeData: tree,
		value,
		onChange,
		placeholder,
		treeCheckable: true,
		showCheckedStrategy: SHOW_PARENT,
		style: {
			width: '100%',
		},
	};

	return (
		<TreeSelect
			{...tProps}
			filterTreeNode={(search, item) =>
				item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
			}
		/>
	);
}
