import React from 'react';
import { Button, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { RootStateOrAny as Root, useDispatch, useSelector } from 'react-redux';

import { Hooks } from '../hooks';
import { Container } from './styles';
import { queryClient } from '@/store/query';
import { setConsolidated } from '@/redux/checklist/actions';
import { ConsolidateChecklistResultDTO } from '../hooks/types';
import { ButtonConsolidated } from '../components/ButtonConsolidated';

const { useConsolidateData, useDesconsolidateData, useDownloadChecklist } = Hooks;

interface UrlParams {
	organization_id: string;
	company_id: string;
	file_d: string;
}

export const Actions: React.FC = () => {
	const dispatch = useDispatch();
	const pdf = useDownloadChecklist();
	const consolidate = useConsolidateData();
	const urlParams = useParams<UrlParams>();
	const desconsolidate = useDesconsolidateData();

	const organization = useSelector((state: Root) => state.organization.organization);
	const checklist = useSelector((state: Root) => state.checklist.checklist);
	const company = useSelector((state: Root) => state.organization.company);
	const file = useSelector((state: Root) => state.checklist.file);

	const messageError = 'Failed to execute the action';

	const isLoadingDownload = pdf.isLoading;
	const isLoadingConsolidated = consolidate.isLoading || desconsolidate.isLoading;

	function normalizePayload() {
		const payload = {
			organization_id: urlParams.organization_id || organization.id,
			company_id: urlParams.company_id || company.id,
			checklist_id: checklist.id,
			file_id: file.id
		};
		return payload;
	}

	function handleDownloadReport(): void {
		const payload = normalizePayload();
		pdf.mutate(payload, {
			onSuccess: (data) => {
				let archive = document.createElement('a');
				archive.target = '_blank';
				archive.rel = 'noopener noreferrer';
				archive.href = data.url;
				archive.click();
				archive.remove();
			},
			onError: () => {
				message.error(I18n.get(messageError));
			}
		});
	}

	function handleConsolidateReport(): void {
		const payload = normalizePayload();
		const manipulatingResults = {
			onSuccess: (data: ConsolidateChecklistResultDTO) => {
				dispatch(setConsolidated(!checklist.consolidated));
				queryClient.invalidateQueries(['files']);
				message.success(I18n.get(data.message));
			},
			onError: () => {
				message.error(I18n.get(messageError));
			}
		};

		checklist.consolidated
			? desconsolidate.mutate(payload, manipulatingResults)
			: consolidate.mutate(payload, manipulatingResults);
	}

	return (
		<Container>
			<Button size="large" type="primary" onClick={handleDownloadReport} loading={isLoadingDownload}>
				<Icons.FilePdfOutlined />
				{I18n.get('Export report')}
			</Button>
			<ButtonConsolidated
				onClick={handleConsolidateReport}
				isLoading={isLoadingConsolidated}
				consolidated={checklist.consolidated}
			/>
		</Container>
	);
};
