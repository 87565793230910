import React, { useState, useContext } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Row, Col, Button, Collapse, Form, Select, Input, DatePicker, InputNumber, Checkbox, Radio } from 'antd';

import { FilesContext } from '../context';
import { useOrganization, useCompany, useSector, useLine, useWorkstation } from '@/hooks/v1';

export function Filter() {
	const [form] = Form.useForm();
	const { updateFilter, resetFilter } = useContext(FilesContext);

	const initialValues = {
		left_upper_arm: 3,
		right_upper_arm: 3,
		right_hand: 3,
		left_hand: 3,
		vibration: 1,
		reaction: 1,
		effort: 1,
		trunk: 3,
		neck: 3
	};

	// Parameters
	const [organizationId, setOrganizationId] = useState('');
	const [companyId, setCompanyId] = useState('');
	const [sectorId, setSectorId] = useState('');
	const [lineId, setLineId] = useState('');
	const [workstationId, setWorkstationId] = useState('');

	// Actions
	const [hasEffort, setHasEffort] = useState(false);
	const [hasVibration, setHasVibration] = useState(false);
	const [hasReaction, setHasReaction] = useState(false);

	// Body parts
	const [hasUpperArmRight, setHasUpperArmRight] = useState(false);
	const [hasUpperArmLeft, setHasUpperArmLeft] = useState(false);
	const [hasRightHand, setHasRightHand] = useState(false);
	const [hasLeftHand, setHasLeftHand] = useState(false);
	const [hasTrunk, setHasTrunk] = useState(false);
	const [hasNeck, setHasNeck] = useState(false);

	const { organizations } = useOrganization();
	const { companies } = useCompany({ organizationId });
	const { sectors } = useSector({ organizationId, companyId });
	const { lines } = useLine({ organizationId, companyId, sectorId });

	const { workstations } = useWorkstation({
		organizationId,
		companyId,
		sectorId,
		lineId
	});

	const disabledCompany = organizationId;
	const disabledSector = disabledCompany && companyId;
	const disabledLine = disabledSector && sectorId;
	const disabledWorkstation = disabledLine && lineId;
	const disabledSubmit = organizationId && companyId;

	function resetActions() {
		setHasEffort(false);
		setHasVibration(false);
		setHasReaction(false);
	}

	function resetBodyParts() {
		setHasUpperArmRight(false);
		setHasUpperArmLeft(false);
		setHasRightHand(false);
		setHasLeftHand(false);
		setHasTrunk(false);
		setHasNeck(false);
	}

	function setOrganization(id) {
		setOrganizationId(id);
	}

	function setCompany(id) {
		setCompanyId(id);
	}

	function setSector(id) {
		setSectorId(id);
	}

	function setLine(id) {
		setLineId(id);
	}

	function setWorkstation(id) {
		setWorkstationId(id);
	}

	function resetState() {
		setOrganizationId('');
		setCompanyId('');
		setSectorId('');
		setLineId('');
		setWorkstationId('');
		resetActions();
		resetBodyParts();
	}

	function onFilter(values) {
		const {
			organization,
			company,
			sector,
			line,
			workstation,
			original_name,
			date,
			effort,
			reaction,
			vibration,
			trunk,
			neck,
			left_hand,
			right_hand,
			left_upper_arm,
			right_upper_arm
		} = values;

		let [start_date, end_date] = [null, null];

		if (date) {
			const [start, end] = date;
			start_date = moment(start).format('YYYY-MM-DD');
			end_date = moment(end).format('YYYY-MM-DD');
		}

		const payload = {
			...(organization && { organization }),
			...(company && { company }),
			...(sector && { sector_id: sector }),
			...(line && { line_id: line }),
			...(workstation && { workstation_id: workstation }),
			...(original_name && { original_name }),
			...(start_date && end_date && { start_date, end_date }),
			...(hasEffort && effort && { effort }),
			...(hasReaction && reaction && { reaction }),
			...(hasVibration && vibration && { vibration }),
			...(hasTrunk && trunk && { trunk }),
			...(hasNeck && neck && { neck }),
			...(hasLeftHand && left_hand && { left_hand }),
			...(hasRightHand && right_hand && { right_hand }),
			...(hasUpperArmLeft && left_upper_arm && { left_upper_arm }),
			...(hasUpperArmRight && right_upper_arm && { right_upper_arm })
		};

		const query = new URLSearchParams(payload);

		updateFilter({
			organizationId: organization,
			companyId: company,
			query: query.toString()
		});
	}

	function onFinish() {
		form.validateFields()
			.then(onFilter)
			.catch((errInfo) => {});
	}

	function onClearForm() {
		resetState();
		resetFilter();
		form.resetFields();
	}

	const onFilterOptions = (input, { children }) => children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	const selectInput = (item, key) => (
		<Select.Option key={key} value={item.id}>
			{item.name}
		</Select.Option>
	);

	return (
		<div>
			<Collapse>
				<Collapse.Panel header={I18n.get('Filters')}>
					<Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
						<Row gutter={8}>
							<Col sm={6}>
								<Form.Item label={I18n.get('Company')} name="organization">
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select a company')}
										optionFilterProp="children"
										onChange={setOrganization}
										loading={organizations.isLoading}
										filterOption={onFilterOptions}
										disabled={organizations.isLoading}
									>
										{organizations.data?.map(selectInput.bind(this))}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<Form.Item label={I18n.get('Industrial Site')} name="company">
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select a industrial site')}
										optionFilterProp="children"
										onChange={setCompany}
										loading={companies.isLoading}
										filterOption={onFilterOptions}
										disabled={!disabledCompany}
									>
										{companies.data?.map(selectInput.bind(this))}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<Form.Item label={I18n.get('Sector')} name="sector">
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select the sector')}
										optionFilterProp="children"
										onChange={setSector}
										loading={sectors.isLoading}
										filterOption={onFilterOptions}
										disabled={!disabledSector}
									>
										{sectors.data?.map(selectInput.bind(this))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col sm={6}>
								<Form.Item label={I18n.get('Production line')} name="line">
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select a production line')}
										optionFilterProp="children"
										onChange={setLine}
										loading={lines.isLoading}
										filterOption={onFilterOptions}
										disabled={!disabledLine}
									>
										{lines.data?.map(selectInput.bind(this))}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<Form.Item label={I18n.get('Workstation')} name="workstation">
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Select the workstation')}
										optionFilterProp="children"
										onChange={setWorkstation}
										loading={workstations.isLoading}
										filterOption={onFilterOptions}
										disabled={!disabledWorkstation}
									>
										{workstations.data?.map(selectInput.bind(this))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col sm={6}>
								<Form.Item label={I18n.get('Video')} name="original_name">
									<Input placeholder={I18n.get('Video description')} style={{ width: '100%' }} />
								</Form.Item>
							</Col>
							<Col sm={6}>
								<Form.Item label={I18n.get('Period')} name="date">
									<DatePicker.RangePicker
										showTime={{ format: 'HH:mm:00' }}
										format="DD-MM-YYYY HH:mm:00"
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<div>
									<Checkbox checked={hasEffort} onChange={() => setHasEffort(!hasEffort)}>
										{I18n.get('Effort')} (kg)
									</Checkbox>
								</div>
								<Form.Item name="effort">
									<InputNumber
										min={1}
										max={26}
										stringMode
										disabled={!hasEffort}
										style={{ width: '25%' }}
									/>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<div>
									<Checkbox checked={hasReaction} onChange={() => setHasReaction(!hasReaction)}>
										{I18n.get('Reaction')} (Torque)
									</Checkbox>
								</div>
								<Form.Item name="reaction">
									<InputNumber
										min={1}
										max={999}
										stringMode
										disabled={!hasReaction}
										style={{ width: '25%' }}
									/>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<div>
									<Checkbox checked={hasVibration} onChange={() => setHasVibration(!hasVibration)}>
										{I18n.get('Vibration')}
									</Checkbox>
								</div>
								<Form.Item name="vibration">
									<Radio.Group disabled={!hasVibration}>
										<Radio value={1}>{I18n.get('Below')} 2,5m/s²</Radio>
										<Radio value={2}>{I18n.get('Above')} 2,5m/s²</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<div>
									<Checkbox checked={hasTrunk} onChange={() => setHasTrunk(!hasTrunk)}>
										{I18n.get('Trunk')}
									</Checkbox>
								</div>
								<Form.Item name="trunk">
									<Radio.Group disabled={!hasTrunk}>
										<Radio value={1}>{I18n.get('High risk')}</Radio>
										<Radio value={2}>{I18n.get('Moderate')}</Radio>
										<Radio value={3}>{I18n.get('Low risk')}</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<div>
									<Checkbox checked={hasNeck} onChange={() => setHasNeck(!hasNeck)}>
										{I18n.get('Neck')}
									</Checkbox>
								</div>
								<Form.Item name="neck">
									<Radio.Group disabled={!hasNeck}>
										<Radio value={1}>{I18n.get('High risk')}</Radio>
										<Radio value={2}>{I18n.get('Moderate')}</Radio>
										<Radio value={3}>{I18n.get('Low risk')}</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<div>
									<Checkbox checked={hasLeftHand} onChange={() => setHasLeftHand(!hasLeftHand)}>
										{I18n.get('Left hand')}
									</Checkbox>
								</div>
								<Form.Item name="left_hand">
									<Radio.Group disabled={!hasLeftHand}>
										<Radio value={1}>{I18n.get('High risk')}</Radio>
										<Radio value={2}>{I18n.get('Moderate')}</Radio>
										<Radio value={3}>{I18n.get('Low risk')}</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<div>
									<Checkbox checked={hasRightHand} onChange={() => setHasRightHand(!hasRightHand)}>
										{I18n.get('Right hand')}
									</Checkbox>
								</div>
								<Form.Item name="right_hand">
									<Radio.Group disabled={!hasRightHand}>
										<Radio value={1}>{I18n.get('High risk')}</Radio>
										<Radio value={2}>{I18n.get('Moderate')}</Radio>
										<Radio value={3}>{I18n.get('Low risk')}</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col sm={6}>
								<div>
									<Checkbox
										checked={hasUpperArmLeft}
										onChange={() => setHasUpperArmLeft(!hasUpperArmLeft)}
									>
										{I18n.get('Left shoulder')}
									</Checkbox>
								</div>
								<Form.Item name="left_upper_arm">
									<Radio.Group disabled={!hasUpperArmLeft}>
										<Radio value={1}>{I18n.get('High risk')}</Radio>
										<Radio value={2}>{I18n.get('Moderate')}</Radio>
										<Radio value={3}>{I18n.get('Low risk')}</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<div>
									<Checkbox
										checked={hasUpperArmRight}
										onChange={() => setHasUpperArmRight(!hasUpperArmRight)}
									>
										{I18n.get('Right shoulder')}
									</Checkbox>
								</div>
								<Form.Item name="right_upper_arm">
									<Radio.Group disabled={!hasUpperArmRight}>
										<Radio value={1}>{I18n.get('High risk')}</Radio>
										<Radio value={2}>{I18n.get('Moderate')}</Radio>
										<Radio value={3}>{I18n.get('Low risk')}</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8} style={{ paddingTop: '20px' }}>
							<Col sm={3}>
								<Button
									type="primary"
									htmlType="submit"
									style={{ width: '100%' }}
									icon={<Icons.SearchOutlined />}
									disabled={!disabledSubmit}
								>
									{I18n.get('Search')}
								</Button>
							</Col>
							<Col sm={3}>
								<Button style={{ width: '100%' }} onClick={onClearForm}>
									{I18n.get('Clear')}
								</Button>
							</Col>
						</Row>
					</Form>
				</Collapse.Panel>
			</Collapse>
		</div>
	);
}
