import React from 'react';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { DefaultOptionType } from 'antd/lib/select';
import { Select, SelectProps, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';

import type { Organization } from '@/types';

type OrganizationSelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	organizationId?: string;
	organizations: Organization[] | undefined;
};

type OptionType = { label: string; value: string } | undefined;

export function OrganizationSelect({
	loading,
	disabled,
	organizations,
	organizationId,
	size = 'middle',
	...props
}: OrganizationSelectProps & SelectProps) {
	function filterOption(option: OptionType | DefaultOptionType | undefined, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	if (loading) {
		return <Skeleton.Input block active size={'small'} />;
	}

	return (
		<Select
			{...props}
			showSearch
			allowClear
			size={size}
			loading={loading}
			disabled={disabled}
			value={organizationId}
			placeholder={I18n.get('Company')}
			filterOption={(input, option) => filterOption(option, input)}
			options={organizations?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
