import styled from 'styled-components';
import { Col, Steps } from 'antd';
import { DIRECTION } from '.';

interface StepsProps {
	direction?: 'vertical' | 'horizontal';
	$stepLength?: number;
}

export const Container = styled(Col)`
	padding: 0 0 2rem 0;
`;

export const CustomSteps = styled(Steps)<StepsProps>`
	align-items: center;
	text-align: center;
	justify-content: ${({ direction, $stepLength }) => {
		if (direction === DIRECTION.HORIZONTAL && $stepLength && $stepLength > 12) {
			return 'start';
		}
		return 'center';
	}};
	height: fit-content;

	.ant-steps-icon {
		.step-hover {
			:hover {
				.step-checked-hovered {
					height: fit-content;
					width: max-content;
					display: block;
					font-size: 1rem;
					cursor: pointer;
					background: none;
					margin-top: 25px;
					padding: 3px 10px;
					border-radius: 20px;
					border: solid 1px #2f54eb;
					background-color: #f0f2f5;
					max-width: 220px;
					.ant-typography {
						color: #2f54eb;
					}
				}

				.step-number-hovered {
					height: fit-content;
					width: max-content;
					display: block;
					font-size: 1rem;
					cursor: pointer;
					background: none;
					margin-top: 25px;
					padding: 3px 10px;
					border-radius: 20px;
					border: solid 1px #b4b5b8;
					background-color: #f0f2f5;
					max-width: 220px;
					.ant-typography {
						color: #b4b5b8;
					}
				}

				.disabled-step {
					cursor: not-allowed;
				}

				.step-checked {
					display: none;
				}

				.step-number {
					display: none;
				}
			}
			.step-checked-hovered {
				display: none;
				width: min-content;
			}

			.step-number-hovered {
				display: none;
			}

			.step-checked:disabled {
				cursor: not-allowed !important;
				filter: contrast(0.8) !important;
			}
			.step-checked-hovered:disabled {
				cursor: not-allowed !important;
				filter: contrast(0.8) !important;
			}

			.step-checked {
				width: 32px;
				height: 32px;
				display: block;
				font-size: 1rem;
				cursor: pointer;
				padding-top: 3px;
				margin-top: 25px;
				border-radius: 20px;
				border: solid 1px #2f54eb;
				max-width: 220px;
				.ant-typography {
					color: #2f54eb;
				}
			}

			.step-number {
				width: 32px;
				height: 32px;
				display: block;
				color: #b4b5b8;
				font-size: 1rem;
				cursor: pointer;
				padding-top: 3px;
				margin-top: 25px;
				border-radius: 20px;
				border: solid 1px #b4b5b8;
			}
		}

		.step-name {
			height: fit-content;
			width: max-content;
			display: block;
			font-size: 1rem;
			margin-top: 25px;
			padding: 3px 10px;
			border-radius: 20px;
			background: #2f54eb;
			max-width: 220px;
			color: #fff;
			.ant-typography {
				color: #fff;
			}
			@media (max-width: 1430px) {
				max-width: 140px;
			}
			@media (max-width: 1200px) {
				max-width: 220px;
			}
			@media (min-width: 2040px) {
				margin-left: 0px;
			}
		}
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-tail::after {
				background-color: #2f54eb;
				width: 1px;
			}
		}
		.ant-steps-item-content {
			.ant-steps-item-title {
				::after {
					background-color: #2f54eb !important;
				}
			}
		}
	}

	.ant-steps-item-container {
		width: min-content;

		.ant-steps-item-tail {
			margin-top: 29px;
			filter: contrast(0.8);
			width: 101% !important;
			padding: 35px 0 10px 0 !important;
			left: 0 !important;
		}
	}

	.ant-steps-item-custom {
		max-width: fit-content !important;
	}
	.ant-steps-item-active {
		max-width: min-content !important;
	}

	.ant-steps-item {
		${({ direction }) => direction === DIRECTION.HORIZONTAL && 'overflow: visible;'}
		margin-left: ${({ direction }) => (direction === DIRECTION.HORIZONTAL ? '12px' : 'unset')};

		.ant-steps-item-container {
			.ant-steps-item-icon {
				margin: 0;
			}
		}
		.ant-steps-item-content {
			display: ${({ direction }) => (direction === DIRECTION.HORIZONTAL ? 'unset' : 'none')};

			.ant-steps-item-description {
				display: none;
			}
			.ant-steps-item-title {
				display: ${({ direction }) => (direction === DIRECTION.HORIZONTAL ? 'unset' : 'none')};
				position: absolute;
				visibility: hidden;

				::after {
					visibility: visible;
					position: absolute;
					top: 40px;
					left: 8px;
					background-color: rgba(0, 0, 0, 0.06);
					width: 10px;
				}
			}
		}
	}
`;
