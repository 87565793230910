import React, { ReactElement, useState } from 'react';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Form, Select, SelectProps, Skeleton } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';
import type { NamePath } from 'antd/lib/form/interface';
import { I18n } from '@aws-amplify/core';

import { useCreateEvaluator } from '@/hooks/useEvaluatorMutations';
import type { CreateEvaluatorDTO } from '../types/evaluator';
import { DropdownRender } from '../../DropdownRender';
import type { Evaluator } from '@/types';

const { useFormInstance } = Form;

type EvaluatorSelectProps = {
	size?: SizeType;
	loading: boolean;
	disabled: boolean;
	companyId: string;
	fieldName: NamePath;
	evaluatorId?: string;
	organizationId: string;
	allowCreation?: boolean;
	evaluators: Evaluator[] | undefined;
};

type OptionType = { label: string; value: string } | undefined;

export function EvaluatorSelect({
	loading,
	disabled,
	fieldName,
	companyId,
	evaluators,
	evaluatorId,
	organizationId,
	size = 'middle',
	allowCreation = true,
	...props
}: EvaluatorSelectProps & SelectProps): JSX.Element {
	const [evaluatorName, setEvaluatorName] = useState('');

	const { validateFields, setFieldValue } = useFormInstance();

	const { mutateAsync: createEvaluator, isLoading } = useCreateEvaluator();

	async function onCreateEvaluator() {
		const body: CreateEvaluatorDTO = {
			organization_id: organizationId,
			company_id: companyId,
			name: evaluatorName
		};

		const product = await createEvaluator({ body });
		const productId = product?.id;
		if (productId) {
			handleChange(productId);
		}
		setEvaluatorName('');
	}

	async function handleChange(id: string) {
		setFieldValue(fieldName, id);
		await validateFields([fieldName]);
	}

	function handleEvaluatorNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		const name = event.target.value;
		setEvaluatorName(name);
	}

	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		return (
			<DropdownRender
				menu={menu}
				loading={isLoading}
				name={evaluatorName}
				onClick={onCreateEvaluator}
				allowCreation={allowCreation}
				onChange={handleEvaluatorNameChange}
			/>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			{...props}
			showSearch
			allowClear
			size={size}
			loading={loading}
			value={evaluatorId}
			disabled={disabled}
			placeholder={I18n.get('Evaluator')}
			dropdownRender={(menu) => showDropdownRender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={evaluators?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
