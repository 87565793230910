import { Col } from 'antd';
import styled, { keyframes } from 'styled-components';

const bounceAnimation = keyframes`
  0% { transform: translateY(0); }
  20% { transform: translateY(-20px); }
  40% { transform: translateY(0px); }
  60% { transform: translateY(-10px); }
  80% { transform: translateY(0px); }
  90% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`;

export const ErrorMessageCol = styled(Col)`
	top: -10vh;
	left: 43%;
	position: absolute;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	backdrop-filter: blur(15px);
	background: linear-gradient(89deg, rgba(231, 65, 80, 0.85) 6.33%, rgba(217, 17, 35, 0.9) 96.55%);
	animation: ${bounceAnimation} 1s ease-in-out;

	span {
		color: #ffffff;
		font-size: 1rem;
		font-weight: 600;
		text-align: center;
		font-style: normal;
		line-height: normal;

		@media (max-width: 1280px) {
			font-size: 0.8rem;
		}
	}
`;
