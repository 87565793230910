import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Radio } from 'antd';

import { Title } from './styles';
import { ComplaintsAndCertificatesContext } from './context';

export const Header: React.FC = () => {
	const { selectToggle, setSelectToggle } = useContext(ComplaintsAndCertificatesContext);

	const description = selectToggle ? 'Medical certificates list' : 'Complaints list';

	const options = [
		{ label: I18n.get('Complaints'), value: false },
		{ label: I18n.get('Certificates'), value: true },
	];

	return (
		<Col sm={24} style={{ paddingBottom: '15px' }}>
			<Title>
				<h2>{I18n.get(description)}</h2>
			</Title>
			<Radio.Group
				options={options}
				optionType="button"
				value={selectToggle}
				onChange={setSelectToggle}
				style={{ display: 'inline', float: 'right' }}
			/>
		</Col>
	);
}
