import React, { useRef } from 'react';
import { Col, Row } from 'antd';

import { HorizontalButtons } from './components/HorizontalButtons';
import { StepIcon } from './components/StepIcon';
import type { Direction, Steps } from './types';
import * as S from './styles';

export enum SIDE {
	LEFT = 'left',
	RIGHT = 'right'
}

export enum DIRECTION {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal'
}

interface StepsNavigationProps {
	steps?: Steps[];
	currentStep: number;
	isComplete: boolean;
	direction?: Direction;
	isConsolidated?: boolean;
	stepsName: { [key: string]: string };
	onStepNagivationClick: (step: number) => Promise<void>;
}

export const StepsNavigation: React.FC<StepsNavigationProps> = ({
	steps,
	stepsName,
	isComplete,
	currentStep,
	isConsolidated,
	onStepNagivationClick,
	direction = DIRECTION.VERTICAL
}) => {
	const stepsLength = steps?.length ?? 0;
	const elementRef = useRef<HTMLDivElement | null>(null);

	const mappedSteps = steps?.map((item, index) => {
		item.icon = (
			<StepIcon
				key={item.name}
				position={index}
				isComplete={isComplete}
				isConsolidated={isConsolidated}
				currentStep={currentStep}
				name={stepsName[item.name] ?? ''}
				onStepNagivationClick={onStepNagivationClick}
			/>
		);
		return item;
	});

	if (direction === DIRECTION.VERTICAL || stepsLength <= 12) {
		return (
			<Row justify="center">
				<S.Container xs={24}>
					<Row justify="center">
						<Col xs={20}>
							<S.CustomSteps
								size="small"
								items={mappedSteps}
								direction={direction}
								current={currentStep}
							/>
						</Col>
					</Row>
				</S.Container>
			</Row>
		);
	}

	return (
		<Row justify="center">
			<S.Container xs={24}>
				<HorizontalButtons elementRef={elementRef}>
					<Col xs={20}>
						<div ref={elementRef} style={{ overflowX: 'hidden' }}>
							<S.CustomSteps
								size="small"
								items={mappedSteps}
								direction={direction}
								current={currentStep}
								$stepLength={stepsLength}
							/>
						</div>
					</Col>
				</HorizontalButtons>
			</S.Container>
		</Row>
	);
};
