import React from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

const Container = styled(Col)`
	padding-top: 20px;
	padding-bottom: 30px;
`;

export const Title = ({ children }) => (
	<Container sm={24}>
		<h2>{I18n.get(children)}</h2>
	</Container>
);
