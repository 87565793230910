import React from 'react';
import moment from 'moment';
import { Table, Tag } from 'antd';
import { I18n } from '@aws-amplify/core';

export function Checklist({ checklists }) {
	const globalGrade = {
		1: {
			text: I18n.get('High risk'),
			tagColor: 'red',
		},
		2: {
			text: I18n.get('Moderate risk'),
			tagColor: 'orange',
		},
		3: {
			text: I18n.get('Without risk'),
			tagColor: 'green',
		},
	};

	const columns = [
		{
			title: 'id',
			dataIndex: 'checklist_id',
			align: 'left',
			render: (text) => <span>{text.substring(0, 8)}</span>,
		},
		{
			title: I18n.get('File'),
			dataIndex: 'original_name',
			render: (_, record) => (
				<a href={record.url} target="_blank" rel="noopener noreferrer">
					{record.original_name}
				</a>
			),
		},
		{
			title: I18n.get('Date of colletion'),
			dataIndex: 'collection_date',
			align: 'center',
			render: (text) => (
				<span>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</span>
			),
		},
		{
			title: I18n.get('Global grade'),
			dataIndex: 'global_grade',
			align: 'center',
			render: (grade) => {
				const gradeConfig = globalGrade[grade];

				return (
					<Tag color={gradeConfig.tagColor}>{gradeConfig.text}</Tag>
				);
			},
		},
	];

	return (
		<Table
			size="small"
			rowKey="checklist_id"
			columns={columns}
			dataSource={checklists}
			pagination={{ pageSize: 5 }}
			title={() => <h4>{I18n.get('Checklists in this workstation')}:</h4>}
		/>
	);
}
