import React, { useEffect, useContext, useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { Row, Col, Form, Checkbox, Button, message } from 'antd';

import { Title } from './Title';
import Api from '@/services/api';
import { AETContext } from '../context';

const { useForm } = Form;
const Container = Row;
const FormContent = Col;

export function LocalApplication() {
	const {
		companies,
		listCompanies,
		setListCompanies,
		nextStep,
		previousStep,
		setData,
	} = useContext(AETContext);

	const [form] = useForm();

	const organization = useSelector(
		(state) => state.organization.organization
	);

	const getCompanies = useCallback(() => {
		if (!organization || listCompanies !== null) return;
		Api.get(`/aet/companies/${organization.id}`)
			.then((res) => setListCompanies(res.data))
			.catch((err) => {})
			.finally(() => {});
	}, [organization, listCompanies]);

	useEffect(() => {
		getCompanies();
	}, [organization]);

	function onFinish(values) {
		if (Array.isArray(values.companies) && !values.companies.length) {
			message.warn(
				I18n.get('Select the industrial sites to be analyzed')
			);
			return;
		}
		setData(values);
		nextStep();
	}

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			style={{ minHeight: '500px' }}
			initialValues={{ companies }}
		>
			<Container>
				<Title>Units to be analyzed</Title>
				<FormContent sm={22}>
					<Row>
						<Col sm={24}>
							<p>
								{I18n.get(
									'Select the units that will be included in the review'
								)}
							</p>
						</Col>
						<Col sm={24}>
							<Form.Item
								name="companies"
								label={I18n.get('Factory')}
							>
								<Checkbox.Group style={{ width: '100%' }}>
									<Row>
										{listCompanies?.map((item, index) => (
											<Col sm={6} key={index}>
												<Checkbox
													key={index}
													value={item.id}
												>
													{item.name}
												</Checkbox>
											</Col>
										))}
									</Row>
								</Checkbox.Group>
							</Form.Item>
						</Col>
					</Row>
				</FormContent>
				<Col sm={24} style={{ position: 'absolute', bottom: 0 }}>
					<Row gutter={[5]}>
						<Col>
							<Button onClick={previousStep}>
								{I18n.get('Previous')}
							</Button>
						</Col>
						<Col>
							<Form.Item shouldUpdate noStyle>
								{() => (
									<Button type="primary" htmlType="submit">
										{I18n.get('Next')}
									</Button>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Container>
		</Form>
	);
}
