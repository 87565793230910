import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import Api from '@/services/api';
import { selectBodyParts, rangeRiskJSON } from '@/redux/reports/selectors';
import { setRangeRiskBands, setData } from '@/redux/reports/actions';
import { LineChartRange } from './LineChart';
import { MultipleCassificationList } from './MultipleCassificationList';

const Wrapper = styled.div``;

const color = ['#CBEDBA', '#FDE6B8', '#FCBDC0', ''].reverse();

const Card = styled.div`
	background: ${(props) => color[props.classification]};
	padding: 20px 15px;
	margin-bottom: 20px;
`;

const Chart = styled.div`
	img {
		width: 100%;
	}
`;

export function BodyParts({ data }) {
	const dispatch = useDispatch();
	const [settings, setSettings] = React.useState(null);
	const [chartData, setChartData] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const query = useParams();

	const rangeRisk = useSelector(rangeRiskJSON);
	const bodyParts = useSelector(selectBodyParts);

	const file = useSelector((state) => state.checklist.file);

	const classifications = [
		I18n.get('Low risk'),
		I18n.get('Moderate Risk'),
		I18n.get('High risk'),
		'',
	].reverse();

	const frequencyClassifications = [
		I18n.get('No repeatability'),
		'',
		I18n.get('With repetition'),
		'',
	].reverse();

	function getParameters() {
		let body = {};

		if (query.organization_id) {
			body['organization_id'] = query.organization_id;
		}

		if (query.organization_id && query.company_id) {
			body['company_id'] = query.company_id;
		}

		if (query.file_id) {
			body['file_id'] = query.file_id;
			body['body_parts'] = bodyParts;
		}

		return body;
	}

	const { organization_id, company_id } = useSelector(
		(state) => ({
			organization_id: state.organization.organization.id,
			company_id: state.organization.company.id,
		}),
		shallowEqual
	);

	React.useEffect(() => {
		if (!settings && rangeRisk) {
			const url = `/customization/risk-ranges/${rangeRisk.id}`;
			Api.get(url)
				.then((response) => {
					setSettings(response.data);
					dispatch(setRangeRiskBands(response.data));
				})
				.catch((error) => console.log('settings error', error))
				.finally(() => setLoading(false));
		}
	}, [settings, rangeRisk]);

	React.useLayoutEffect(() => {
		if (
			chartData ||
			loading ||
			!organization_id ||
			!company_id ||
			!file ||
			!bodyParts
		)
			return;
		setLoading(true);

		let body = getParameters();

		if (Object.keys(body).length === 0) {
			body = {
				organization_id: organization_id,
				company_id: company_id,
				file_id: file.id,
				body_parts: bodyParts,
			};
		}

		Api.post('/report/checklist/body-parts/angles', body)
			.then((response) => {
				setChartData(response.data);
				dispatch(setData(response.data));
			})
			.catch((err) => console.log('data error', err));
	}, [organization_id, company_id, file, bodyParts, loading]);

	const items = [
		...((data.duration_left_hand &&
			data.duration_left_hand && [
				{
					title: I18n.get('Left fist'),
					duration: data.duration_left_hand,
					frequency: data.frequency_left_hand,
					chart: chartData?.left_hand,
					settings: settings?.left_hand,
				},
			]) ||
			[]),
		...((data.duration_right_hand &&
			data.frequency_right_hand && [
				{
					title: I18n.get('Right fist'),
					duration: data.duration_right_hand,
					frequency: data.frequency_right_hand,
					chart: chartData?.right_hand,
					settings: settings?.right_hand,
				},
			]) ||
			[]),
		{
			title: I18n.get('Left Shoulder/Arm'),
			duration: data.duration_left_upper_arm,
			frequency: data.frequency_left_upper_arm,
			chart: chartData?.left_upper_arm,
			settings: settings?.left_upper_arm,
		},
		{
			title: I18n.get('Right Shoulder/Arm'),
			duration: data.duration_right_upper_arm,
			frequency: data.frequency_right_upper_arm,
			chart: chartData?.right_upper_arm,
			settings: settings?.right_upper_arm,
		},
		{
			title: I18n.get('Trunk'),
			duration: data.duration_trunk,
			frequency: data.frequency_trunk,
			chart: chartData?.trunk,
			settings: settings?.trunk,
		},
		{
			title: I18n.get('Cervical'),
			duration: data.duration_neck,
			frequency: data.frequency_neck,
			chart: chartData?.neck,
			settings: settings?.neck,
		},
	];

	return (
		<Wrapper>
			<Row gutter={16}>
				{items.map((item, index) => (
					<Col key={index} span={12}>
						<Card classification={item.duration}>
							<h3>{item.title}</h3>
							<Chart>
								<LineChartRange
									title={item.title}
									data={item.chart}
									config={item.settings}
								/>
							</Chart>
							<Row style={{ textAlign: 'center' }}>
								<Col span={12}>
									<h3 style={{ margin: 0, paddingTop: 15 }}>
										{I18n.get('Duration')}
									</h3>
									<p style={{ marginBottom: 0 }}>
										{classifications[item.duration]}
									</p>
								</Col>
								<Col span={12}>
									<h3 style={{ margin: 0, paddingTop: 15 }}>
										{I18n.get('Frequency')}
									</h3>
									<p style={{ marginBottom: 0 }}>
										{frequencyClassifications[item.frequency]}
									</p>
								</Col>
							</Row>
						</Card>
					</Col>
				))}

				<Col span={12}>
					<MultipleCassificationList
						title={I18n.get('Lower members')}
						items={[
							{
								name: I18n.get('Squat '),
								classification: data.squat,
							},
							{
								name: I18n.get('Displacement '),
								classification: data?.displacement,
							},
						]}
					/>
				</Col>
			</Row>
		</Wrapper>
	);
}
