import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

const StyledList = styled(List)`
	margin-bottom: 40px;

	& .ant-list-header {
		font-weight: bold;
	}

	& .ant-list-footer {
		padding: 0;
	}
`;

export function MultipleCassificationList({
	title,
	items,
	classificationText,
}) {
	const color = ['#CBEDBA', '#FDE6B8', '#FCBDC0', ''].reverse();
	const classificationTexts = [
		I18n.get('Low risk'),
		I18n.get('Moderate Risk'),
		I18n.get('High risk'),
		'',
	].reverse();

	return (
		<StyledList
			header={<div>{title}</div>}
			bordered
			dataSource={items}
			renderItem={(item) => (
				<List.Item style={{ background: color[item.classification] }}>
					<List.Item.Meta
						title={item.name}
						description={item.description}
					/>
					<div>
						{classificationText
							? classificationText
							: classificationTexts[item.classification]}
					</div>
				</List.Item>
			)}
		/>
	);
}
